<template>
  <v-container class="pt-0" fluid style="padding-bottom: 110px">
    <div class="text-h5 position-sticky" style="top:48px;">
      项目管理
      <small>— {{ projectInfo.name }} ( ID:{{ projectInfo.id }} )</small>
      <v-chip class="ml-4" color="primary" small text-color="white">
        当前状态：{{
          projectInfo.project_status ? projectInfo.project_status.text : "异常"
        }}
      </v-chip>
    </div>
    <div class="position-sticky pt-1 pb-3" style="top:82px;">
      <div style="border-bottom: 1px solid #ddd;"></div>
    </div>

    <template>
      <!----------------------------------------------
			- 根据stepPageList循环生成步骤条
			------------------------------------------------>
      <v-stepper v-model.lazy="step" class="overflow-visible">
	      <v-stepper-header class="position-sticky" style="top:95px">
		      <template v-for="(stepPage, stepIndex) in stepPageList">
			      <v-stepper-step
					      :key="'step_' + stepIndex"
					      :editable="page_permissions.steps.includes(stepIndex + 1)"
					      :step="stepIndex + 1"
					      style="font-size:14px"
			      >{{ stepPage.title }}
			      </v-stepper-step>
			      <v-divider
              v-if="stepIndex + 1 < stepPageList.length"
              :key="stepIndex"
            ></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <template v-for="(stepPage, stepIndex) in stepPageList">
            <v-stepper-content
              :key="'content_' + stepIndex"
              :step="stepIndex + 1"
              class="py-2"
            >
              <v-card outlined>
                <v-sheet class="mb-3">
                  <!----------------------------------------------
									- 各页面根据tabs生成标签页
									------------------------------------------------>
	                <v-tabs v-model.lazy="stepPage.tab" dense grow>
		                <template v-for="(tabItem, tabIndex) in stepPage.tabs">
			                <v-tab
					                v-show="
                          page_permissions['step_' + (stepIndex + 1)] &&
                            page_permissions[
                              'step_' + (stepIndex + 1)
                            ].tabs.includes(tabIndex)
                        "
					                :key="'tab_' + stepIndex + '_' + tabIndex"
					                style="border:1px solid #1976d2"
                      >
                        {{ tabItem.label }}
                      </v-tab>
                    </template>
                  </v-tabs>
                </v-sheet>
	              <v-tabs-items v-model.lazy="stepPage.tab">
		              <template v-for="(tabItem, tabIndex) in stepPage.tabs">
			              <v-tab-item
					              v-show="
                        page_permissions['step_' + (stepIndex + 1)] &&
                          page_permissions[
                            'step_' + (stepIndex + 1)
                          ].tabs.includes(tabIndex)
                      "
					              :key="'tab_' + stepIndex + '_' + tabIndex"
			              >
                      <!----------------------------------------------
											- 生成链接、表单、表格、证书等元素
											------------------------------------------------>
                      <v-responsive>
                        <!-- 链接数据 -->
                        <template v-if="tabItem.link">
                          <v-row class="px-1" dense>
                            <v-col
                              v-for="(linkItem, linkIndex) in tabItem.link"
                              :key="
                                'link_' +
                                  stepIndex +
                                  '_' +
                                  tabIndex +
                                  '_' +
                                  linkIndex
                              "
                              class="pa-0"
                              cols="4"
                            >
                              <v-row class="py-0" no-gutters>
                                <v-col
                                  class="text-truncate text-end"
                                  cols="4"
                                  style="font-size:14px;line-height:40px;"
                                >
                                  <span>{{ linkItem.label }} :</span>
                                </v-col>
                                <v-col
                                  class="px-2"
                                  cols="8"
                                  style="font-size:14px;line-height:40px;"
                                >
                                  <a
                                    v-if="linkItem.type === 'file'"
                                    :href="
                                      'https://' +
                                        (getMediaObject(linkItem.category)[0]
                                          ? getMediaObject(linkItem.category)[0]
                                              .media_file.mediaurl
                                          : '')
                                    "
                                    target="_blank"
                                    >{{ linkItem.label }}</a
                                  >
                                  <a
                                    v-else
                                    @click.stop="
                                      link_show(
                                        linkItem.step,
                                        linkItem.tab,
                                        linkItem.type
                                      )
                                    "
                                    >{{ linkItem.label }}</a
                                  >
                                  <small class="ml-2">(点此链接查看详情)</small>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </template>
                        <!-- 表单数据 -->
                        <template v-if="tabItem.form">
                          <v-row class="mt-1 mx-1 py-1" dense>
                            <v-col
                              v-for="(formItem, formIndex) in tabItem.form"
                              :key="
                                'form_' +
                                  stepIndex +
                                  '_' +
                                  tabIndex +
                                  '_' +
                                  formIndex
                              "
                              :cols="
                                formItem.cols
                                  ? formItem.cols
                                  : stepIndex === 0
                                  ? 6
                                  : 4
                              "
                              :offset="formItem.offset ? formItem.offset : 0"
                              class="pa-0"
                            >
                              <v-row class="py-1" no-gutters>
                                <v-col
                                  :cols="formItem.cols === 12 ? 2 : 4"
                                  :style="
                                    formItem.type !== 'text' || stepIndex !== 0
                                      ? 'line-height:40px;'
                                      : ''
                                  "
                                  class="text-truncate text-end"
                                  style="font-size:14px;"
                                >
                                  <span>{{ formItem.label }} :</span>
                                </v-col>
                                <v-col
                                  :cols="formItem.cols === 12 ? 10 : 8"
                                  class="px-2"
                                  style="font-size:14px;"
                                >
                                  <!--文字-->
                                  <span
                                    v-if="
                                      formItem.type === 'text' || (!formItem.type) || (page_permissions['step_' + (stepIndex + 1)]&&page_permissions['step_' + (stepIndex + 1)].form&&page_permissions['step_' + (stepIndex + 1)].form[tabIndex])
                                    "
                                    :style="
                                      stepIndex !== 0 ? 'line-height:40px;' : ''
                                    "
                                  >
                                    <span v-if="tabItem.project_status === 0">
                                      {{
		                                    projectInfo[formItem.field]
				                                    ? projectInfo[formItem.field]
				                                    : "暂无数据"
	                                    }}
                                    </span>
	                                  <span v-else-if="tabItem.project_status === 2">
		                                  <a v-if="projectInfo.files[3]"
		                                     @click.stop="file_preview(projectInfo.files[3].media_file)">{{
				                                  projectInfo.files[3].media_file.name
			                                  }}</a>
	                                  </span>
																		<template v-else-if="[20].includes(formItem.project_status)">
																			<span v-if="formItem.field === 'test_score'">
																				{{ getCertificateHandle(reviewDecision['review_20']['test_score']) }}
																			</span>
																			<span v-else-if="formItem.field === 'expert'">
																				{{ getUserName(reviewDecision['review_20']['expert']) }}
																			</span>
																			<span v-else>
																				{{
																					reviewDecision['review_20'][formItem.field] ? reviewDecision['review_20'][formItem.field] : '暂无数据'
																				}}
																			</span>
																		</template>
                                    <template
		                                    v-else-if="
                                        [1, 13, 15, 17, 33, 35, 37].includes(
                                          formItem.project_status
                                        )
                                      "
                                    >
                                      <strong
		                                      v-if="formItem.field === 'test_result'"
		                                      :style="
                                          'color:' +
                                            (reviewDecision[
                                              'review_' +
                                                formItem.project_status
                                            ][formItem.field]
                                              ? 'green'
                                              : 'red')
                                        "
                                      >
                                        {{
                                          reviewDecision[
                                            "review_" + formItem.project_status
                                          ][formItem.field]
                                            ? "通过"
                                            : "不通过"
                                        }}
                                      </strong>
                                      <span
                                        v-else-if="
                                          formItem.field === 'expert' &&
                                            reviewDecision[
                                              'review_' +
                                                formItem.project_status
                                            ][formItem.field]
                                        "
                                      >
                                        {{
                                          getUserName(
                                            reviewDecision[
                                              "review_" +
                                                formItem.project_status
                                            ][formItem.field]
                                          )
                                        }}
                                      </span>
                                      <span v-else>
                                        {{
                                          reviewDecision[
                                            "review_" + formItem.project_status
                                          ][formItem.field]
                                            ? reviewDecision[
                                                "review_" +
                                                  formItem.project_status
                                              ][formItem.field]
                                            : "暂无数据"
                                        }}
                                      </span>
                                    </template>

                                    <span
                                      v-else
                                      :style="
                                        stepIndex !== 0
                                          ? 'line-height:40px;'
                                          : ''
                                      "
                                      class="grey--text"
                                    >暂无数据</span
                                    >
                                  </span>

	                                <!--文本输入框-->
	                                <template v-else-if="formItem.type === 'input'">
		                                <v-text-field
				                                v-if="tabItem.project_status === 0"
				                                v-model.lazy="projectInfo[formItem.field]"
				                                :disabled="formItem.disabled||form_disabled(0)"
				                                dense
				                                hide-details
				                                outlined
		                                ></v-text-field>
		                                <v-text-field
				                                v-else-if="
                                        [1, 13, 15, 17, 33, 35, 37,20].includes(
                                          tabItem.project_status
                                        )
                                      "
				                                v-model.lazy="
                                        reviewDecision[
                                          'review_' + tabItem.project_status
                                        ][formItem.field]
                                      "
				                                :disabled="formItem.disabled||form_disabled(0)"
				                                dense
				                                hide-details
				                                outlined
		                                ></v-text-field>
	                                </template>

	                                <!--下拉选择-->
	                                <template v-else-if="formItem.type === 'select'">
		                                <v-select
				                                v-if="tabItem.project_status === 0"
				                                v-model.lazy="projectInfo[formItem.field]"
				                                :items="typeListHandle(formItem.field)"
				                                :disabled="formItem.disabled||form_disabled(0)"
				                                dense
				                                hide-details
				                                outlined
		                                >
			                                <template
					                                v-if="
                                        formItem.field ===
                                          'applying_star_rating'
                                      "
					                                v-slot:item="{ item }"
			                                >
				                                <v-rating
						                                :value="item.value"
						                                background-color="orange lighten-3"
						                                color="orange"
						                                length="3"
						                                small
				                                ></v-rating>
			                                </template>
			                                <template
					                                v-if="
                                        formItem.field ===
                                          'applying_star_rating'
                                      "
					                                v-slot:selection="{ item }"
			                                >
				                                <v-rating
						                                :value="item.value"
						                                background-color="orange lighten-3"
						                                color="orange"
						                                length="3"
						                                small
				                                ></v-rating>
			                                </template>
		                                </v-select>
		                                <v-select
				                                v-if="tabItem.project_status === 20"
				                                v-model.lazy="reviewDecision[
                                          'review_' + tabItem.project_status
                                        ][formItem.field]"
				                                :items="certificateHandle"
				                                dense
				                                :disabled="formItem.disabled||form_disabled(0)"
				                                hide-details
				                                outlined
				                                placeholder="选择证书处理"
		                                ></v-select>
	                                </template>

	                                <!--日期选择-->
	                                <template v-else-if="formItem.type === 'date'">
		                                <v-menu
				                                v-if="tabItem.project_status === 0"
				                                v-model.lazy="formItem.show"
				                                :close-on-content-click="false"
				                                :nudge-right="40"
				                                min-width="290px"
				                                offset-y
				                                transition="scale-transition"
		                                >
			                                <template
					                                v-slot:activator="{ on, attrs }"
			                                >
				                                <v-text-field
						                                v-model.lazy="projectInfo[formItem.field]"
						                                v-bind="attrs"
						                                v-on="on"
						                                dense
						                                hide-details
						                                outlined
						                                :disabled="formItem.disabled||form_disabled(0)"
						                                prepend-icon="mdi-calendar"
						                                readonly
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
		                                      v-model.lazy="projectInfo[formItem.field]"
		                                      locale="zh-cn"
		                                      :disabled="formItem.disabled||form_disabled(0)"
		                                      @input="formItem.show = false"
                                      ></v-date-picker>
                                    </v-menu>
                                    <v-menu
		                                    v-if="
                                        [10, 30].includes(
                                          tabItem.project_status
                                        )
                                      "
		                                    v-model.lazy="formItem.show"
		                                    :close-on-content-click="false"
		                                    :nudge-right="40"
		                                    min-width="290px"
		                                    offset-y
		                                    transition="scale-transition"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
		                                        v-model.lazy="
                                            assign_limit_date[
                                              'assign_' + tabItem.project_status
                                            ]
                                          "
		                                        v-bind="attrs"
		                                        v-on="on"
		                                        :disabled="formItem.disabled||form_disabled(0)"
		                                        dense
		                                        hide-details
		                                        outlined
		                                        prepend-icon="mdi-calendar"
		                                        readonly
                                        ></v-text-field>
                                      </template>
	                                    <v-date-picker
			                                    v-model.lazy="
                                          assign_limit_date[
                                            'assign_' + tabItem.project_status
                                          ]
                                        "
			                                    :disabled="formItem.disabled||form_disabled(0)"
			                                    :allowedDates="allowedDates"
			                                    locale="zh-cn"
			                                    @input="formItem.show = false"
	                                    ></v-date-picker>
                                    </v-menu>
		                                <v-menu
				                                v-if="
                                        [20].includes(
                                          tabItem.project_status
                                        )
                                      "
				                                v-model.lazy="formItem.show"
				                                :close-on-content-click="false"
				                                :nudge-right="40"
				                                min-width="290px"
				                                offset-y
				                                transition="scale-transition"
		                                >
			                                <template
					                                v-slot:activator="{ on, attrs }"
			                                >
				                                <v-text-field
						                                v-model.lazy="reviewDecision[
                                          'review_' + tabItem.project_status
                                        ][formItem.field]"
						                                v-bind="attrs"
						                                v-on="on"
						                                dense
						                                hide-details
						                                outlined
						                                :disabled="formItem.disabled||form_disabled(0)"
						                                prepend-icon="mdi-calendar"
						                                readonly
				                                ></v-text-field>
			                                </template>
			                                <v-date-picker
					                                v-model.lazy="reviewDecision[
                                          'review_' + tabItem.project_status
                                        ][formItem.field]"
					                                :allowedDates="allowedDates"
					                                locale="zh-cn"
					                                :disabled="formItem.disabled||form_disabled(0)"
					                                @input="formItem.show = false"
			                                ></v-date-picker>
		                                </v-menu>
	                                </template>

	                                <!--单选-->
	                                <template v-else-if="formItem.type === 'radio'">
		                                <v-radio-group
				                                v-if="tabItem.project_status === 0"
				                                v-model.lazy="projectInfo[formItem.field]"
				                                :disabled="formItem.disabled||form_disabled(0)"
				                                dense
				                                hide-details
				                                row
				                                small
		                                >
			                                <v-radio
					                                v-for="(radioItem,
                                        radioIndex) in typeListHandle(
                                          formItem.field
                                        )"
					                                :key="radioIndex"
					                                :label="radioItem.text"
					                                :value="radioItem.value"
					                                dense
					                                hide-details
					                                small
                                      ></v-radio>
                                    </v-radio-group>
                                    <!--证书等级-->
	                                  <v-radio-group
			                                  v-if="
                                        formItem.project_status === 20
                                      "
			                                  v-model.lazy="reviewDecision[
                                          'review_' + tabItem.project_status
                                        ][formItem.field]"
			                                  dense
			                                  :disabled="formItem.disabled||form_disabled(0)"
			                                  hide-details
			                                  row
			                                  small
	                                  >
		                                  <v-radio
				                                  dense
				                                  hide-details
				                                  :value="1"
				                                  label="一星"
				                                  small
		                                  ></v-radio>
		                                  <v-radio
				                                  dense
				                                  hide-details
				                                  :value="2"
				                                  label="二星"
				                                  small
		                                  ></v-radio>
		                                  <v-radio
				                                  dense
				                                  hide-details
				                                  :value="3"
				                                  label="三星"
				                                  small
		                                  ></v-radio>
                                    </v-radio-group>
                                    <v-radio-group
		                                    v-if="
                                        [1, 13, 15, 17, 33, 35, 37].includes(
                                          tabItem.project_status
                                        )
                                      "
		                                    v-model.lazy="
                                        reviewDecision[
                                          'review_' + tabItem.project_status
                                        ][formItem.field]
                                      "
		                                    dense
		                                    :disabled="formItem.disabled||form_disabled(0)"
		                                    hide-details
		                                    row
		                                    small
                                    >
                                      <v-radio
                                        :value="true"
                                        dense
                                        hide-details
                                        label="通过"
                                        small
                                      ></v-radio>
	                                    <v-radio
			                                    :value="false"
			                                    dense
			                                    hide-details
			                                    label="不通过"
			                                    small
	                                    ></v-radio>
                                    </v-radio-group>
	                                </template>

	                                <v-switch
			                                v-else-if="formItem.type === 'switch'"
			                                v-model.lazy="projectInfo[formItem.field]"
			                                :disabled="formItem.disabled||form_disabled(0)"
			                                :label="
                                      projectInfo[formItem.field]
                                        ? '符合'
                                        : '不符合'
                                    "
			                                dense
			                                hide-details
			                                inset
	                                >
	                                </v-switch>

	                                <!--文件上传-->
	                                <template v-else-if="formItem.type === 'upload'">
		                                <v-row
				                                v-for="(fileItem,
                                      fileIndex) in file_handle(
                                        formItem.category
                                      )"
				                                :key="formItem.category + '_' + fileIndex"
				                                dense
		                                >
			                                <v-col cols="10">
				                                <v-file-input
						                                v-model.lazy="
                                            projectInfo.files[fileItem]
                                              .media_file
                                          "
						                                :clearable="false"
						                                :loading="
                                            projectInfo.files[fileItem].loading
                                          "
						                                dense
						                                hide-details
		                                        :disabled="formItem.disabled||form_disabled(0)"
		                                        outlined
                                        >
                                          <template v-slot:selection="{ text }">
                                            <v-chip
                                              v-if="
                                                projectInfo.files[fileItem]
                                                  .media_file.name
                                              "
                                              color="primary"
                                              label
                                              small
                                              @click.stop="
                                                file_preview(
                                                  projectInfo.files[fileItem]
                                                    .media_file
                                                )
                                              "
                                            >
                                              {{
                                                projectInfo.files[fileItem]
                                                  .media_file.name
                                                  ? projectInfo.files[fileItem]
                                                      .media_file.name
                                                  : text
                                              }}
                                            </v-chip>
                                          </template>
                                        </v-file-input>
                                      </v-col>
	                                    <v-col class="text-center" cols="2">
		                                    <template v-if="formItem.disabled||form_disabled(0)">
			                                    <v-btn
					                                    color="grey"
					                                    icon
					                                    @click="
                                            file_preview(
                                                  projectInfo.files[fileItem]
                                                    .media_file
                                                )
                                          "
			                                    >
				                                    <v-icon>mdi-eye-outline</v-icon>
			                                    </v-btn>
		                                    </template>
		                                    <template v-else>
			                                    <v-progress-circular
					                                    v-if="
                                            projectInfo.files[fileItem]
                                              .progress > 0 &&
                                              projectInfo.files[fileItem]
                                                .progress < 100
                                          "
					                                    :value="
                                            projectInfo.files[fileItem].progress
                                          "
					                                    color="primary"
					                                    small
			                                    >
				                                    <small>
					                                    {{
						                                    projectInfo.files[fileItem]
								                                    .progress
					                                    }}
					                                    %</small
				                                    >
			                                    </v-progress-circular>

			                                    <v-btn
					                                    v-else
					                                    :disabled="
                                            !!projectInfo.files[fileItem]
                                              .media_file.mediaurl ||
                                              !projectInfo.files[fileItem]
                                                .media_file.name
                                          "
					                                    color="blue"
					                                    icon
					                                    @click="
                                            file_upload(
                                              formItem.category,
                                              fileItem
                                            )
                                          "
			                                    >
				                                    <v-icon>mdi-upload</v-icon>
			                                    </v-btn>

			                                    <v-btn
					                                    color="red"
					                                    icon
					                                    @click="
                                            file_remove(
                                              formItem.category,
                                              fileItem
                                            )
                                          "
			                                    >
				                                    <v-icon>mdi-minus-circle</v-icon>
			                                    </v-btn>

			                                    <v-btn
					                                    v-if="
                                            fileIndex >=
                                              file_handle(formItem.category)
                                                .length -
                                                1
                                          "
					                                    color="green"
					                                    icon
					                                    @click="file_add(formItem.category)"
			                                    >
				                                    <v-icon>mdi-plus-circle</v-icon>
			                                    </v-btn>
			                                    <v-btn v-else icon text></v-btn>
		                                    </template>

	                                    </v-col>
                                    </v-row>
                                    <v-row
                                      v-if="
                                        file_handle(formItem.category)
                                          .length === 0
                                      "
                                    >
                                      <v-col class="text-center" cols="2">
                                        <v-btn
                                          color="green"
                                          icon
                                          @click="file_add(formItem.category)"
                                        >
                                          <v-icon>mdi-plus-circle</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </template>

                                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                        </template>
                        <!-- 表格数据 -->
                        <template
                          v-if="
                            tabItem.table &&
                              tabItem.table.type === 'testitems_table'
                          "
                        >
                          <v-row class="mt-1 pa-1" dense>
                            <v-card class="mx-2" outlined>
                              <!----------------------------------------------
															- 表格上方筛选
															------------------------------------------------>
                              <v-card
                                class="mb-5 py-2"
                                outlined
                                style="border:1px solid #1976d2"
                              >
                                <v-row dense justify="center">
                                  <v-col cols="2">
                                    <h2
                                      class="text-center"
                                      style="line-height:40px"
                                    >
                                      条文筛选：
                                    </h2>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-select
		                                    v-model.lazy="filters_selected.lvl_1"
		                                    :items="filters.lvl_1"
		                                    dense
		                                    hide-details
		                                    label="大类"
		                                    multiple
		                                    outlined
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-select
		                                    v-model.lazy="filters_selected.lvl_2"
		                                    :items="filters.lvl_2"
		                                    dense
		                                    hide-details
		                                    label="子类"
		                                    multiple
		                                    outlined
                                    ></v-select>
                                  </v-col>
                                </v-row>
                              </v-card>

                              <!----------------------------------------------
															- 表格
															------------------------------------------------>
                              <v-data-table
		                              v-model.lazy="testitems_table.selected"
		                              :expanded="testitems_table.formdata"
		                              :headers="
                                  testitems_table_headers_handlers(
                                    tabItem.table.header,
                                    tabItem.table.actions
                                  )
                                "
		                              :items="testitems_table.formdata"
		                              :show-expand="
                                  !!(
                                    tabItem.table.actions &&
                                    tabItem.table.actions.includes('expand')
                                  )
                                "
		                              :show-select="
                                  !!(
                                    tabItem.table.actions &&
                                    tabItem.table.actions.includes('select')
                                  )
                                "
		                              disable-pagination
		                              hide-default-footer
		                              item-key="test_item"
		                              style="border-top:1px solid #eeeeee"
                              >
                                <template
                                  v-slot:expanded-item="{ headers, item }"
                                >
                                  <td
                                    v-show="item.active"
                                    :colspan="headers.length"
                                    class="grey lighten-4 px-0"
                                  >
                                    <!----------------------------------------------
																		- 表格内行扩展
																		------------------------------------------------>
                                    <v-container class="px-0" fluid>
                                      <v-row class="px-2" dense>
                                        <!----------------------------------------------
																				- 自评价
																				------------------------------------------------>
                                        <v-col class="px-0" cols="12">
                                          <v-card
                                            class="grey lighten-4"
                                            outlined
                                            style="border:1px solid rgba(0,0,0,0.12) !important;"
                                          >
                                            <v-card-subtitle class="px-3 py-1">
                                              <strong>自评价</strong>
                                            </v-card-subtitle>
                                            <v-divider class="mb-1"></v-divider>
                                            <v-card-text class="px-2 py-1 ">
                                              <v-row no-gutters>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >自评得分:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <v-text-field
		                                                      v-if="
                                                          step === 1 &&
                                                            stepPage.tab === 1
                                                        "
		                                                      v-model.lazy="
                                                          item.review_0
                                                            .test_score
                                                        "
		                                                      :error="
                                                          item.review_0
                                                            .test_score >
                                                            item.score
                                                        "
		                                                      :disabled="form_disabled(0)"
		                                                      dense
		                                                      hide-details
		                                                      outlined
                                                      ></v-text-field>
                                                      <span
                                                        v-else
                                                        style="line-height:40px"
                                                        v-text="
                                                          item.review_0
                                                            .test_score
                                                            ? item.review_0
                                                                .test_score
                                                            : '暂无数据'
                                                        "
                                                      ></span>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >相关说明:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <v-text-field
		                                                      v-if="
                                                          step === 1 &&
                                                            stepPage.tab === 1
                                                        "
		                                                      v-model.lazy="
                                                          item.review_0
                                                            .test_text
                                                        "
		                                                      dense
		                                                      :disabled="form_disabled(0)"
		                                                      hide-details
		                                                      outlined
                                                      ></v-text-field>
                                                      <span
                                                        v-else
                                                        style="line-height:40px"
                                                        v-text="
                                                          item.review_0
                                                            .test_text
                                                            ? item.review_0
                                                                .test_text
                                                            : '暂无数据'
                                                        "
                                                      ></span>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >提交时间:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <span
                                                        style="line-height:40px"
                                                        v-text="
                                                          item.review_0
                                                            .update_time
                                                            ? item.review_0
                                                                .update_time
                                                            : '暂无数据'
                                                        "
                                                      ></span>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                                <v-col cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >证明文件:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <v-file-input
		                                                      v-if="
                                                          step === 1 &&
                                                            stepPage.tab === 1&&(!form_disabled(0))
                                                        "
		                                                      v-model.lazy="
                                                          item.review_0.file
                                                            .media_file
                                                        "
		                                                      :clearable="false"
		                                                      :loading="
                                                          item.review_0.file
                                                            .loading
                                                        "
		                                                      :disabled="form_disabled(0)"
		                                                      append-icon="mdi-upload"
		                                                      dense
		                                                      hide-details
		                                                      outlined
		                                                      @click:append="
                                                          file_upload(
                                                            11,
                                                            item.index
                                                          )
                                                        "
                                                      >
                                                        <template
                                                          v-slot:selection="{
                                                            text,
                                                          }"
                                                        >
                                                          <v-chip
                                                            v-if="
                                                              item.review_0.file
                                                                .media_file.name
                                                            "
                                                            color="primary"
                                                            label
                                                            small
                                                            @click.stop="
                                                              file_preview(
                                                                item.review_0
                                                                  .file
                                                                  .media_file
                                                              )
                                                            "
                                                          >
                                                            {{
                                                              item.review_0.file
                                                                .media_file.name
                                                                ? item.review_0
                                                                    .file
                                                                    .media_file
                                                                    .name
                                                                : text
                                                            }}
                                                          </v-chip>
                                                        </template>
                                                      </v-file-input>
                                                      <a
                                                        v-else-if="
                                                          item.review_0.file
                                                            .media_file.mediaurl
                                                        "
                                                        :href="
                                                          'https://' +
                                                            item.review_0.file
                                                              .media_file
                                                              .mediaurl
                                                        "
                                                        style="line-height:40px"
                                                        target="_blank"
                                                        v-text="
                                                          item.review_0.file
                                                            .media_file.name
                                                        "
                                                      ></a>
                                                      <span
                                                        v-else
                                                        style="line-height:40px"
                                                        >暂无数据</span
                                                      >
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>

                                        <!----------------------------------------------
																				- 专家评价
																				------------------------------------------------>
                                        <v-col
                                          v-if="
                                            (step === 4 || step === 5) &&
                                              stepPage.tab !== 0
                                          "
                                          class="px-0"
                                          cols="12"
                                        >
                                          <v-card
                                            class="grey lighten-4"
                                            outlined
                                            style="border:1px solid rgba(0,0,0,0.12) !important;"
                                          >
                                            <v-card-subtitle class="px-3 py-1">
                                              <strong>评价</strong>
                                            </v-card-subtitle>
                                            <v-divider class="mb-1"></v-divider>
                                            <v-card-text class="px-2 py-1 ">
                                              <v-row no-gutters>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >评价得分:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <v-text-field
		                                                      v-if="
                                                          step === 4 &&
                                                            stepPage.tab === 1
                                                        "
		                                                      v-model.lazy="
                                                          item.review_11
                                                            .test_score
                                                        "
		                                                      :error="
                                                          item.review_11
                                                            .test_score >
                                                            item.score
                                                        "
		                                                      :disabled="form_disabled(11)"
		                                                      dense
		                                                      hide-details
		                                                      outlined
                                                      ></v-text-field>
                                                      <span
                                                        v-else
                                                        style="line-height:40px"
                                                        v-text="
                                                          item.review_11
                                                            .test_score
                                                            ? item.review_11
                                                                .test_score
                                                            : '暂无数据'
                                                        "
                                                      ></span>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >评价意见:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <v-text-field
		                                                      v-if="
                                                          step === 4 &&
                                                            stepPage.tab === 1
                                                        "
		                                                      v-model.lazy="
                                                          item.review_11
                                                            .test_text
                                                        "
		                                                      :disabled="form_disabled(11)"
		                                                      dense
		                                                      hide-details
		                                                      outlined
                                                      ></v-text-field>
                                                      <span
                                                        v-else
                                                        style="line-height:40px"
                                                        v-text="
                                                          item.review_11
                                                            .test_text
                                                            ? item.review_11
                                                                .test_text
                                                            : '暂无数据'
                                                        "
                                                      ></span>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >评价人员:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <span
                                                        style="line-height:40px"
                                                        v-text="
                                                          item.review_11.expert
                                                            ? getUserName(
                                                                item.review_11
                                                                  .expert
                                                              )
                                                            : '暂无数据'
                                                        "
                                                      ></span>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >专家报告:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <v-file-input
		                                                      v-if="
                                                          step === 4 &&
                                                            stepPage.tab === 1&&(!form_disabled(11))
                                                        "
		                                                      v-model.lazy="
                                                          item.review_11.file
                                                            .media_file
                                                        "
		                                                      :clearable="false"
		                                                      :loading="
                                                          item.review_11.file
                                                            .loading
                                                        "
		                                                      :disabled="form_disabled(11)"
		                                                      append-icon="mdi-upload"
		                                                      dense
		                                                      hide-details
		                                                      outlined
		                                                      @click:append="
                                                          file_upload(
                                                            12,
                                                            item.index
                                                          )
                                                        "
                                                      >
                                                        <template
                                                          v-slot:selection="{
                                                            text,
                                                          }"
                                                        >
                                                          <v-chip
                                                            v-if="
                                                              item.review_11
                                                                .file.media_file
                                                                .name
                                                            "
                                                            color="primary"
                                                            label
                                                            small
                                                            @click.stop="
                                                              file_preview(
                                                                item.review_11
                                                                  .file
                                                                  .media_file
                                                              )
                                                            "
                                                          >
                                                            {{
                                                              item.review_11
                                                                .file.media_file
                                                                .name
                                                                ? item.review_11
                                                                    .file
                                                                    .media_file
                                                                    .name
                                                                : text
                                                            }}
                                                          </v-chip>
                                                        </template>
                                                      </v-file-input>
                                                      <a
                                                        v-else-if="
                                                          item.review_11.file
                                                            .media_file.mediaurl
                                                        "
                                                        :href="
                                                          'https://' +
                                                            item.review_11.file
                                                              .media_file
                                                              .mediaurl
                                                        "
                                                        style="line-height:40px"
                                                        target="_blank"
                                                        v-text="
                                                          item.review_11.file
                                                            .media_file.name
                                                        "
                                                      ></a>
                                                      <span
                                                        v-else
                                                        style="line-height:40px"
                                                        >暂无数据</span
                                                      >
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row no-gutters>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >评价时间:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <span
                                                        style="line-height:40px"
                                                        v-text="
                                                          item.review_11
                                                            .update_time
                                                            ? item.review_11
                                                                .update_time
                                                            : '暂无数据'
                                                        "
                                                      ></span>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>

                                        <!----------------------------------------------
																				- 专家分配
																				------------------------------------------------>
                                        <v-col
                                          v-if="
                                            (step === 4 || step === 5) &&
                                              stepPage.tab === 0
                                          "
                                          class="px-0"
                                          cols="12"
                                        >
                                          <v-card
                                            class="grey lighten-4"
                                            outlined
                                            style="border:1px solid rgba(0,0,0,0.12) !important;"
                                          >
                                            <v-card-subtitle class="px-3 py-1">
                                              <strong
                                                >{{
                                                  step === 5 ? "监督" : ""
                                                }}专家分配</strong
                                              >
                                            </v-card-subtitle>
                                            <v-divider class="mb-1"></v-divider>
                                            <v-card-text class="px-2 py-1 ">
                                              <v-row no-gutters>
                                                <v-col class="mb-1" cols="4">
                                                  <v-row dense>
                                                    <v-col
                                                      class="py-0 text-center text-truncate"
                                                      cols="3"
                                                      style="line-height:40px"
                                                      >评价人员:
                                                    </v-col>
                                                    <v-col
                                                      class="py-0"
                                                      cols="9"
                                                    >
                                                      <v-select
		                                                      v-model.lazy="
                                                          assignedList[
                                                            item.test_item
                                                          ][
                                                            'assign_' +
                                                              (step === 4
                                                                ? '10'
                                                                : '30')
                                                          ].user
                                                        "
		                                                      :items="
                                                          getUserGroup(
                                                            step === 4
                                                              ? '专家（评价）'
                                                              : '专家（监督）'
                                                          )
                                                        "
		                                                      :disabled="form_disabled(step === 4?10:30)"
		                                                      dense
		                                                      hide-details
		                                                      item-text="username"
		                                                      item-value="id"
		                                                      outlined
		                                                      placeholder="选择专家"
		                                                      @change="
                                                          expert_selected_change(
                                                            item.test_item,
                                                            step === 4
                                                              ? '10'
                                                              : '30',
                                                            $event
                                                          )
                                                        "
                                                      >
                                                      </v-select>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>

                                        <!----------------------------------------------
																				- 监督评价
																				------------------------------------------------>
                                        <v-col
                                          v-if="
                                            step === 5 && stepPage.tab !== 0
                                          "
                                          class="px-0"
                                          cols="12"
                                        >
                                          <v-card
                                            class="grey lighten-4"
                                            outlined
                                            style="border:1px solid rgba(0,0,0,0.12) !important;"
                                          >
	                                          <v-card-subtitle class="px-3 py-1">
		                                          <strong>监督</strong>
		                                          <v-spacer></v-spacer>
		                                          <v-btn
				                                          v-show="!form_disabled(31)"
				                                          :disabled="form_disabled(31)"
				                                          class="mr-2"
				                                          icon
				                                          small
				                                          style="position:absolute;right:0;top: 2px"
				                                          @click="
                                                  review_31_add(item.test_item)
                                                "
		                                          >
			                                          <v-icon color="green"
			                                          >mdi-plus-circle
			                                          </v-icon
			                                          >
		                                          </v-btn>
	                                          </v-card-subtitle>
                                            <v-divider class="mb-1"></v-divider>
                                            <v-card-text class="px-2 py-1 ">
                                              <template
                                                v-for="(review_31_item,
                                                review_31_index) in item.review_31"
                                              >
                                                <v-row
                                                  v-if="
                                                    review_31_item.base_status
                                                  "
                                                  :key="
                                                    'review_31_' +
                                                      item.test_item +
                                                      '_' +
                                                      review_31_index
                                                  "
                                                  no-gutters
                                                >
                                                  <v-col class="mb-1" cols="4">
                                                    <v-row no-gutters>
                                                      <v-col
                                                        class="py-0 text-center text-truncate"
                                                        cols="3"
                                                        style="line-height:40px"
                                                        >监督结果:
                                                      </v-col>
                                                      <v-col
                                                        class="py-0"
                                                        cols="9"
                                                      >
                                                        <v-text-field
		                                                        v-if="
                                                            step === 5 &&
                                                              stepPage.tab === 1
                                                          "
		                                                        v-model.lazy="
                                                            review_31_item.test_text
                                                          "
		                                                        dense
		                                                        :disabled="form_disabled(31)"
		                                                        hide-details
		                                                        outlined
                                                        ></v-text-field>
                                                        <span
                                                          v-else
                                                          style="line-height:40px"
                                                          v-text="
                                                            review_31_item.test_text
                                                              ? review_31_item.test_text
                                                              : '暂无数据'
                                                          "
                                                        ></span>
                                                      </v-col>
                                                    </v-row>
                                                  </v-col>
                                                  <v-col class="mb-1" cols="4">
                                                    <v-row no-gutters>
                                                      <v-col
                                                        class="py-0 text-center text-truncate"
                                                        cols="3"
                                                        style="line-height:40px"
                                                        >监督人员:
                                                      </v-col>
                                                      <v-col
                                                        class="py-0"
                                                        cols="9"
                                                      >
                                                        <span
                                                          style="line-height:40px"
                                                          v-text="
                                                            review_31_item.expert
                                                              ? getUserName(
                                                                  review_31_item.expert
                                                                )
                                                              : '暂无数据'
                                                          "
                                                        ></span>
                                                      </v-col>
                                                    </v-row>
                                                  </v-col>
                                                  <v-col class="mb-1" cols="3">
                                                    <v-row no-gutters>
                                                      <v-col
                                                        class="py-0 text-center text-truncate"
                                                        cols="3"
                                                        style="line-height:40px"
                                                        >提交时间:
                                                      </v-col>
                                                      <v-col
                                                        class="py-0"
                                                        cols="9"
                                                      >
                                                        <span
                                                          style="line-height:40px"
                                                          v-text="
                                                            review_31_item.update_time
                                                              ? review_31_item.update_time
                                                              : '暂无数据'
                                                          "
                                                        ></span>
                                                      </v-col>
                                                    </v-row>
                                                  </v-col>
	                                                <v-col
			                                                class="mb-1 text-right"
			                                                cols="1"
	                                                >
		                                                <v-btn
				                                                v-show="!form_disabled(31)"
				                                                :disabled="form_disabled(31)"
				                                                icon
				                                                small
				                                                style="height:40px"
				                                                @click="
                                                        review_31_remove(
                                                          item.test_item,
                                                          review_31_index
                                                        )
                                                      "
		                                                >
			                                                <v-icon color="red"
			                                                >mdi-minus-circle
			                                                </v-icon
			                                                >
		                                                </v-btn>
                                                  </v-col>
                                                </v-row>
                                              </template>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </td>
                                </template>
                                <template v-slot:[`item.index`]="{ item }">
                                  {{ item.index + 1 }}
                                </template>
                                <template v-slot:[`item.name`]="{ item }">
                                  <span
                                    v-html="
                                      item.name
                                        .replace(/：/g, ': <br/> ')
                                        .replace(/；/g, '； <br/> ')
                                    "
                                  ></span
                                ></template>
                                <template v-slot:[`item.active`]="{ item }">
                                  <v-row justify="center">
                                    <v-switch
		                                    v-if="tabItem.project_status === 0"
		                                    v-model.lazy="item.active"
		                                    color="green"
                                    ></v-switch>
                                    <v-icon
                                      v-else
                                      :color="item.active ? 'green' : 'grey'"
                                    >
                                      mdi-{{
                                        item.active
                                          ? "checkbox-marked-circle"
                                          : "cancel"
                                      }}
                                    </v-icon>
                                  </v-row>
                                </template>
                                <template
                                  v-slot:[`item.review_score`]="{ item }"
                                >
                                  <span v-if="tabItem.project_status === 0">
                                    ---
                                  </span>
                                  <span v-else>
                                    {{
                                      item["review_11"].test_score
                                        ? item["review_11"].test_score
                                        : item["review_11"].test_score !== 0
                                        ? "---"
                                        : 0
                                    }}
                                  </span>
                                </template>
                              </v-data-table>
                            </v-card>
                          </v-row>
                        </template>
                        <!-- 证书 -->
                        <template v-if="tabItem.certificate">
	                        <v-row no-gutters>
		                        <v-col cols="6" style="border-right: 1px solid #eeeeee">
			                        <div class="text-center my-2">
				                        <v-btn class="mr-2" color="green" dark outlined small>证书下载</v-btn>
			                        </div>
			                        <v-img
					                        contain
					                        max-height="550"
					                        src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=648314370,304671527&fm=26&gp=0.jpg"
			                        ></v-img>
		                        </v-col>
		                        <v-col cols="6">
			                        <div class="text-center my-2">
				                        <v-btn color="orange" dark outlined small @click="certificateDialog.show=true">证书变更
				                        </v-btn>
			                        </div>
			                        <!--			                        <v-container class="px-10">-->
			                        <!--				                        <v-row no-gutters>-->
			                        <!--					                        <v-col cols="2" style="line-height:40px">-->
			                        <!--						                        <strong>变更类型</strong>-->
			                        <!--					                        </v-col>-->
			                        <!--					                        <v-col-->
			                        <!--							                        class="text-right"-->
			                        <!--							                        cols="10"-->
			                        <!--							                        style="line-height:70px"-->
			                        <!--					                        >-->
			                        <!--						                        <v-select-->
			                        <!--								                        :items="[{text:'延期',value:1},{text:'修改',value:2}]"-->
			                        <!--								                        dense-->
			                        <!--								                        hide-details-->
			                        <!--								                        outlined-->
			                        <!--								                        placeholder="选择变更类型"-->
			                        <!--						                        ></v-select>-->
			                        <!--					                        </v-col>-->

			                        <!--				                        </v-row>-->
			                        <!--				                        <v-row class="mt-2" no-gutters>-->
			                        <!--					                        <v-col cols="2" style="line-height:40px">-->
			                        <!--						                        <strong>变更原因</strong>-->
			                        <!--					                        </v-col>-->
			                        <!--					                        <v-col cols="10">-->
			                        <!--						                        <v-text-field v-model.lazy="reviewDecision['review_20']['test_text']" dense-->
			                        <!--						                                      hide-details outlined required></v-text-field>-->
			                        <!--					                        </v-col>-->
			                        <!--				                        </v-row>-->
			                        <!--				                        <v-row class="mt-2" no-gutters>-->
			                        <!--					                        <v-col cols="2" style="line-height:40px">-->
			                        <!--						                        <strong>申请文件</strong>-->
			                        <!--					                        </v-col>-->
			                        <!--					                        <v-col cols="10">-->
			                        <!--						                        <v-file-input dense hide-details outlined></v-file-input>-->
			                        <!--					                        </v-col>-->
			                        <!--				                        </v-row>-->
			                        <!--				                        <v-row class="mt-2" no-gutters>-->
			                        <!--					                        <v-col-->
			                        <!--							                        class="text-right"-->
			                        <!--							                        cols="12"-->
			                        <!--							                        style="line-height:70px"-->
			                        <!--					                        >-->
			                        <!--						                        <v-btn color="success" outlined small>-->
			                        <!--							                        提交-->
			                        <!--						                        </v-btn>-->
			                        <!--					                        </v-col>-->
			                        <!--				                        </v-row>-->
			                        <!--			                        </v-container>-->
		                        </v-col>
	                        </v-row>
                        </template>
                      </v-responsive>
                    </v-tab-item>
                  </template>
                </v-tabs-items>
              </v-card>
            </v-stepper-content>
          </template>
        </v-stepper-items>
      </v-stepper>
    </template>

    <!----------------------------------------------
		- 链接数据弹出展示窗口
		------------------------------------------------>
    <template>
	    <v-dialog v-model.lazy="linkDialog.show" scrollable width="1400px">
		    <v-card outlined>
			    <v-card-title>
				    <span class="headline">{{ linkDialog.data.label }}</span>
				    <v-spacer></v-spacer>
				    <v-btn icon small @click="linkDialog.show = false">
					    <v-icon>mdi-close</v-icon>
				    </v-btn>
			    </v-card-title>
			    <v-card-text style="height:80vh">
				    <v-container fluid>
              <!-- 表单数据 -->
              <template v-if="linkDialog.type === 'form'">
                <v-divider class="my-2"></v-divider>
                <v-row
                  class="mt-1 mx-1 py-1"
                  dense
                  style="border:1px solid #eeeeee"
                >
                  <v-col
                    v-for="(formItem, formIndex) in linkDialog.data.form"
                    :key="'dialog_form_' + formIndex"
                    class="pa-0"
                    cols="6"
                  >
                    <v-row class="py-1" no-gutters>
                      <v-col
                        class="text-truncate text-end"
                        cols="3"
                        style="font-size:14px;"
                      >
                        <span>{{ formItem.label }} :</span>
                      </v-col>
                      <v-col class="px-2" cols="9" style="font-size:14px;">
                        <v-img
                          v-if="formItem.type === 'upload'"
                          :src="
                            'https://' +
                              getMediaObject(formItem.category)[0].media_file
                                .mediaurl
                          "
                          height="25vh"
                        ></v-img>
                        <span v-else-if="formItem.field" class="grey--text">
                          {{ projectInfo[formItem.field] }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </template>
              <!-- 表格数据 -->
              <template v-if="linkDialog.type === 'table'">
                <v-divider class="my-2"></v-divider>
                <v-row class="mt-1 pa-1" dense>
                  <v-card outlined>
                    <!----------------------------------------------
										- 表格上方筛选
										------------------------------------------------>
                    <v-card
                      class="mb-2 py-2"
                      outlined
                      style="border:1px solid #1976d2"
                    >
                      <v-row class="my-2" dense justify="center">
                        <v-col cols="2">
                          <h2 class="text-center" style="line-height:40px">
                            条文筛选：
                          </h2>
                        </v-col>
                        <v-col cols="3">
                          <v-select
		                          v-model.lazy="filters_selected.lvl_1"
		                          :items="filters.lvl_1"
		                          dense
		                          hide-details
		                          label="大类"
		                          multiple
		                          outlined
                          ></v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-select
		                          v-model.lazy="filters_selected.lvl_2"
		                          :items="filters.lvl_2"
		                          dense
		                          hide-details
		                          label="子类"
		                          multiple
		                          outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!----------------------------------------------
										- 表格
										------------------------------------------------>
                    <v-divider></v-divider>
                    <v-row no-gutters>
                      <v-col cols="2"
                        >条文总分：{{ getTotalScore().testitem }}</v-col
                      >
                      <v-col cols="2"
                        >自评总分：{{ getTotalScore().review_0 }}</v-col
                      >
                      <v-col cols="2"
                        >评审总分：{{ getTotalScore().review_11 }}</v-col
                      >
                      <v-col cols="2"
                        >初评结果：<strong
                          :style="
                            'color:' +
                              (reviewDecision.review_13.test_result
                                ? 'green'
                                : 'red')
                          "
                          >{{
                            reviewDecision.review_13.test_result
                              ? "通过"
                              : "未通过"
                          }}</strong
                        >
                      </v-col>
                      <v-col cols="2"
                        >复评结果：<strong
                          :style="
                            'color:' +
                              (reviewDecision.review_15.test_result
                                ? 'green'
                                : 'red')
                          "
                          >{{
                            reviewDecision.review_15.test_result
                              ? "通过"
                              : "未通过"
                          }}</strong
                        >
                      </v-col>
                      <v-col cols="2"
                        >认定结果：<strong
                          :style="
                            'color:' +
                              (reviewDecision.review_17.test_result
                                ? 'green'
                                : 'red')
                          "
                          >{{
                            reviewDecision.review_17.test_result
                              ? "通过"
                              : "未通过"
                          }}</strong
                        >
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-data-table
                      :expanded="testitems_table.formdata"
                      :headers="
                        linkDialog.data.table.header
                          ? testitems_table.headers.concat(
                              linkDialog.data.table.header
                            )
                          : testitems_table.headers
                      "
                      :items="testitems_table.formdata"
                      disable-pagination
                      hide-default-footer
                      item-key="test_item"
                    >
                      <template
                        v-if="linkDialog.data.table.expand"
                        v-slot:expanded-item="{ headers }"
                      >
                        <!----------------------------------------------
												- 表格内表单元素
												------------------------------------------------>
                        <td
                          :colspan="headers.length"
                          class="grey lighten-4 px-0"
                        >
                          <v-container class="px-0" fluid>
                            <v-row class="px-4" dense>
                              <v-col
                                v-for="(expand, expandIndex) in linkDialog.data
                                  .table.expand"
                                :key="'dialog_expand_' + expandIndex"
                                cols="12"
                              >
                                <v-card
                                  class="grey lighten-4"
                                  outlined
                                  style="border:1px solid rgba(0,0,0,0.12) !important;"
                                >
                                  <v-card-subtitle class="px-3 py-2">
                                    <strong>{{ expand.label }}</strong>
                                  </v-card-subtitle>
                                  <v-divider class="mb-1"></v-divider>
                                  <v-card-text class="pa-0 pb-1">
                                    <v-row dense>
                                      <v-col
                                        v-for="(expandItem,
                                        index) in expand.items"
                                        :key="
                                          'dialog_expand_' +
                                            expandIndex +
                                            '_' +
                                            index
                                        "
                                        class="pa-0"
                                        cols="3"
                                      >
                                        <v-row class="px-3" dense>
                                          <v-col
                                            class="text-end text-truncate"
                                            cols="4"
                                          >
                                            {{ expandItem.label }} ：
                                          </v-col>
                                          <v-col cols="8">
                                            <span class="grey--text"
                                              >暂无数据</span
                                            >
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </td>
                      </template>
                      <template v-slot:[`item.index`]="{ item }">
                        {{ item.index + 1 }}
                      </template>
                      <template v-slot:[`item.name`]="{ item }">
                        <span
                          v-html="
                            item.name
                              .replace(/：/g, ': <br/> ')
                              .replace(/；/g, '； <br/> ')
                          "
                        ></span
                      ></template>
                      <template v-slot:[`item.active`]="{ item }">
                        <v-row justify="center">
                          <v-icon :color="item.active ? 'green' : 'grey'">
                            mdi-{{
                              item.active ? "checkbox-marked-circle" : "cancel"
                            }}
                          </v-icon>
                        </v-row>
                      </template>
                      <template v-slot:[`item.review_score`]="{ item }">
                        <span>
                          {{
                            item["review_11"].test_score
                              ? item["review_11"].test_score
                              : item["review_11"].test_score !== 0
                              ? "---"
                              : 0
                          }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

	  <!----------------------------------------------
		- 证书页面-证书处理表单
		------------------------------------------------>
    <template>
	    <v-dialog v-model.lazy="certificateDialog.show" scrollable width="800px">
		    <v-card outlined>
			    <v-card-title>
				    <span class="headline">证书变更处理</span>
				    <v-spacer></v-spacer>
				    <v-btn icon small @click="certificateDialog.show = false">
					    <v-icon>mdi-close</v-icon>
				    </v-btn>
			    </v-card-title>
			    <v-divider class="my-1"></v-divider>
			    <v-card-text style="height:40vh">
            <v-container fluid>
              <!-- 表单数据 -->
              <template>
                <v-row align="center" class="mt-1 mx-1 py-1" no-gutters>
                  <v-col cols="12">
                    <v-card>
                      <v-card-text class="pa-1">
	                      <v-container>
		                      <v-row no-gutters>
			                      <v-col cols="2" style="line-height:56px">
				                      <strong>变更类型</strong>
			                      </v-col>
			                      <v-col
					                      class="text-right"
					                      cols="10"
					                      style="line-height:70px"
			                      >
				                      <v-select
						                      :items="[{text:'延期',value:1},{text:'修改',value:2}]"
						                      dense
						                      hide-details
						                      outlined
						                      placeholder="选择变更类型"
				                      ></v-select>
			                      </v-col>

		                      </v-row>
		                      <v-row no-gutters>
			                      <v-col cols="2" style="line-height:56px">
				                      <strong>变更原因</strong>
			                      </v-col>
			                      <v-col cols="10">
				                      <v-text-field v-model.lazy="reviewDecision['review_20']['test_text']" outlined
				                                    required></v-text-field>
			                      </v-col>
		                      </v-row>
		                      <v-row no-gutters>
                            <v-col cols="2" style="line-height:56px">
                              <strong>申请文件</strong>
                            </v-col>
                            <v-col cols="10">
                              <v-file-input outlined> </v-file-input>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
	                          <v-col
			                          class="text-right"
			                          cols="12"
			                          style="line-height:70px"
	                          >
		                          <v-btn class="mr-4" @click="certificateDialog.show = false">
			                          取消
		                          </v-btn>
		                          <v-btn color="success" @click="certificateDialog.show =show">
			                          提交
		                          </v-btn>
	                          </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <!----------------------------------------------
		- 底部切换页面/提交按钮
		------------------------------------------------>
    <template>
      <!-- 上一步按钮 -->
      <v-fab-transition>
        <v-btn
          bottom
          fab
          fixed
          right
          small
          style="right:167px;bottom:100px;z-index:1"
          @click="changeStep(-1)"
        >
          <v-icon class="mr-1">mdi-chevron-double-left</v-icon>
        </v-btn>
      </v-fab-transition>
      <!-- 上一页按钮 -->
      <v-fab-transition>
        <v-btn
          bottom
          fab
          fixed
          right
          small
          style="right:117px;bottom:100px;z-index:1"
          @click="changeTab(-1)"
        >
          <v-icon class="mr-1">mdi-chevron-left</v-icon>
        </v-btn>
      </v-fab-transition>
      <!-- 下一页按钮 -->
      <v-fab-transition>
        <v-btn
          bottom
          fab
          fixed
          right
          small
          style="right:67px;bottom:100px;z-index:1"
          @click="changeTab(1)"
        >
          <v-icon class="ml-1">mdi-chevron-right</v-icon>
        </v-btn>
      </v-fab-transition>
      <!-- 下一步按钮 -->
      <v-fab-transition>
        <v-btn
          bottom
          fab
          fixed
          right
          small
          style="right:17px;bottom:100px;z-index:1"
          @click="changeStep(1)"
        >
          <v-icon class="mr-1">mdi-chevron-double-right</v-icon>
        </v-btn>
      </v-fab-transition>
      <!-- 保存提交按钮 -->
	    <v-fab-transition v-if="saveBtnShow()&&!(form_disabled())">
		    <v-btn
				    v-if="saveBtnShow()"
				    bottom
				    color="warning"
				    dark
				    fixed
				    right
				    style="right:120px;bottom:50px;z-index:1"
				    @click="submit('save')"
		    >
          <v-icon class="mr-1">mdi-content-save-outline</v-icon>
          保存
        </v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
		        v-if="saveBtnShow()&&!(form_disabled())"
		        bottom
		        color="success"
		        dark
		        fixed
		        right
		        style="bottom:50px;z-index:1"
		        @click="submit('submit')"
        >
          <v-icon class="mr-1">mdi-cloud-upload-outline</v-icon>
          提交
        </v-btn>
      </v-fab-transition>
    </template>
  </v-container>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
//引入Api
import {
  addMedia,
  getProjectInfo,
  removeMedia,
  updateMedia,
  updateProject,
} from "@/api/projects";
import { getBuildingTypeList, getTypeList } from "@/api/dicts";
import { getExpertList } from "@/api/users";
import {
  addTestItemAssignments,
  getTestItemArray,
  getTestItemAssignments,
} from "@/api/testitems";
import { addReview, getReviews } from "@/api/reviews";

export default {
  name: "projectDetails",
  props: {
    id: [String, Number],
  },
  data: () => {
    return {
      //当前步骤条
      step: 1,
      //当前登陆用户信息
      user: JSON.parse(localStorage.getItem("user")),
      //页面访问权限
      page_permissions: {
        steps: [],
      },
      //testitems默认表格结构
      testitems_table: {
        headers: [
          {
            text: "#",
            value: "index",
            align: "center",
            sortable: false,
            divider: true,
            width: "3%",
          },
          {
            text: "条文编号",
            value: "code",
            align: "center",
            divider: true,
            sortable: false,
            width: "7%",
          },
          {
            text: "大类",
            value: "lvl_1",
            align: "center",
            divider: true,
            sortable: false,
            width: "8%",
          },
          {
            text: "子类",
            value: "lvl_2",
            align: "center",
            divider: true,
            sortable: false,
            width: "8%",
          },
          {
            text: "条文内容",
            value: "name",
            divider: true,
            sortable: false,
            width: "45%",
          },
          {
            text: "条文总分",
            value: "score",
            divider: true,
            align: "center",
            sortable: false,
            width: "7%",
          },
          {
            text: "评价得分",
            divider: true,
            value: "review_score",
            align: "center",
            sortable: false,
            width: "7%",
          },
          {
            text: "是否参评",
            value: "active",
            divider: true,
            align: "center",
            sortable: false,
            width: "10%",
          },
        ],
        formdata: [],
        formdata_bak: [],
        selected: [],
      },
      //表格筛选
      filters: {
        lvl_1: [],
        lvl_2: [],
        lvl_2_bak: [],
      },
      //表格筛选-选中
      filters_selected: {
        lvl_1: [],
        lvl_2: [],
      },
      //生成各页面结构数据
      stepPageList: [
        {
          title: "认证申请",
          tab: 0,
          tabs: [
            {
              label: "项目信息",
              project_status: 0,
              form: [
                {
                  label: "项目名称",
                  small:
                    "项目名称应采用规范名称；否则，可能影响后续文件和（或）标识署名的准确性。一旦项目受理，项目名称将不能再修改。",
                  require: true,
                  field: "name",
                  type: "input",
                },
                {
                  label: "项目编号",
                  small:
                    "项目名称应采用规范名称；否则，可能影响后续文件和（或）标识署名的准确性。一旦项目受理，项目名称将不能再修改。",
                  require: true,
                  field: "id",
                  type: "input",
                  disabled: true,
                },
                {
                  label: "项目简介",
                  small: "",
                  require: true,
                  field: "description",
                  type: "input",
                  cols: 12,
                },
                {
                  label: "项目外观图",
                  small: "",
                  require: true,
                  field: "",
                  type: "upload",
                  cols: 12,
                  category: 1,
                },
                {
                  label: "施工许可证编号",
                  small: "",
                  require: true,
                  field: "construction_permit",
                  type: "input",
                },
                {
                  label: "审图合格证编号",
                  small: "",
                  require: true,
                  field: "cd_checking_certificate",
                  type: "input",
                },
                {
                  label: "所在地区",
                  small: "",
                  require: true,
                  field: "province",
                  type: "input",
                },
                {
                  label: "具体地址",
                  small: "",
                  require: true,
                  field: "address",
                  type: "input",
                },
                {
                  label: "用地面积",
                  small: "",
                  require: true,
                  field: "building_area",
                  type: "input",
                  offset: 0,
                },
                {
                  label: "申报建筑面积",
                  small: "不含未申报部分",
                  require: true,
                  field: "declare_building_area",
                  type: "input",
                },
                {
                  label: "建筑类型",
                  small: "",
                  require: true,
	                field: "building_type",
	                type: "radio",
                },
	              {
		              label: "详细建筑类型",
		              small: "",
		              require: true,
		              field: "sub_building_type",
		              type: "select",
	              },
	              {
		              label: "评价标准",
		              small: "",
		              require: true,
		              field: "review_system",
		              type: "select",
	              },
	              {
		              label: "申请星级",
		              small: "",
		              require: true,
		              field: "applying_star_rating",
		              type: "select",
	              },
	              {
		              label: "楼栋数量",
		              small: "",
                  require: true,
                  field: "building_quantity",
                  type: "input",
                },
	              {
		              label: "层数",
		              small:
				              "需填写总层数、地上层数、地下层数。如项目为多栋建筑且层数不同，请在文本框中详细填写各栋建筑信息。",
		              require: true,
		              field: "building_storey",
		              cols: 6,
		              type: "input",
	              },
                {
                  label: "绿色建筑要求",
                  small: "",
                  require: true,
                  field: "green_building_requirement",
                  type: "switch",
                },
                {
                  label: "全装修要求",
                  small: "",
                  require: true,
                  field: "full_decoration_requirement",
                  type: "switch",
                },
                {
                  label: "开工日期",
                  small: "",
                  require: true,
                  field: "starting_date",
                  type: "date",
                  show: false,
                },
                {
                  label: "竣工日期",
                  small: "",
                  require: true,
                  field: "completion_date",
                  type: "date",
                  show: false,
                },
                {
                  label: "业主单位",
                  small: "",
                  require: true,
                  field: "owner",
                  type: "input",
                },
                {
                  label: "设计单位",
                  small: "设计标识必填",
                  require: true,
                  field: "design_company",
                  type: "input",
                },
                {
                  label: "咨询单位",
                  small: "",
                  require: true,
                  field: "consulting_company",
                  type: "input",
                },
                {
                  label: "施工单位",
                  small: "",
                  require: true,
                  field: "constructing_company",
                  type: "input",
                },
                {
                  label: "物业单位",
                  small: "运行标识必填",
                  require: true,
                  field: "pm_company",
                  type: "input",
                },
                {
                  label: "申报单位",
                  small: "",
                  require: true,
                  field: "applying_company",
                  type: "input",
                },
                {
                  label: "项目材料联系人",
                  small: "",
                  require: true,
                  field: "contact",
                  type: "input",
                },
                {
                  label: "联系人固定电话",
                  small: "",
                  require: true,
                  field: "officephone",
                  type: "input",
                },
                {
                  label: "联系人手机",
                  small: "",
                  require: true,
                  field: "cellphone",
                  type: "input",
                },
                {
                  label: "项目材料提交时间",
                  small: "",
                  require: true,
                  field: "update_time",
                  type: "input",
                  disabled: true,
                },
                {
                  label: "项目申报申明",
                  small: "",
                  require: true,
                  field: "",
                  type: "upload",
                  cols: 12,
                  category: 2,
                },
              ],
            },
            {
              label: "自评价",
              project_status: 0,
              table: {
                type: "testitems_table",
                actions: ["expand"],
              },
            },
          ],
        },
        {
          title: "认证受理",
          tab: 0,
          tabs: [
            {
              label: "认证受理",
              project_status: 1,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "项目申报声明",
                  step: 1,
                  tab: 0,
                  type: "file",
                  category: 2,
                },
              ],
              form: [
                {
                  label: "受理结果",
                  small: "",
                  require: true,
                  type: "radio",
                  project_status: 1,
                  field: "test_result",
                  cols: 6,
                },
                {
                  label: "受理意见",
                  small: "",
                  require: true,
                  type: "input",
                  project_status: 1,
                  field: "test_text",
                  cols: 6,
                },
                {
                  label: "认证申请受理人员",
                  type: "text",
                  project_status: 1,
                  field: "expert",
                  cols: 6,
                },
                {
                  label: "认证申请受理时间",
                  type: "text",
                  project_status: 1,
                  field: "update_time",
                  cols: 6,
                },
              ],
              table: {
                type: "testitems_table",
                actions: ["expand"],
              },
            },
          ],
        },
        {
          title: "协议签署",
          tab: 0,
          tabs: [
            {
              label: "协议签署",
              project_status: 2,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "项目申报声明",
                  step: 1,
                  tab: 0,
                  type: "file",
                  category: 2,
                },
              ],
              form: [
                {
                  label: "上传协议书",
                  require: true,
                  type: "upload",
                  cols: 12,
                  category: 3,
                },
              ],
            },
          ],
        },
        {
          title: "认证评价",
          tab: 0,
          tabs: [
            {
              label: "专家分配",
              project_status: 10,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "项目申报声明",
                  step: 1,
                  tab: 0,
                  type: "file",
                  category: 2,
                },
              ],
              form: [
                {
                  label: "是否签署协议",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "是否缴费",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "认证申请受理人员",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "认证申请受理时间",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "审核方案管理人员",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "审核方案更新时间",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "受理意见",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "评审截止时间",
                  type: "date",
                  show: false,
                  cols: 4,
                },
              ],
              table: {
                type: "testitems_table",
                actions: ["select", "expand"],
              },
            },
            {
              label: "评价",
              project_status: 11,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "项目申报声明",
                  step: 1,
                  tab: 0,
                  type: "file",
                  category: 2,
                },
              ],
              form: [
                {
                  label: "认证申请受理人员",
                  type: "text",
                  field: "review_1_user",
                  cols: 4,
                },
                {
                  label: "认证申请受理时间",
                  type: "text",
                  field: "review_1_date",
                  cols: 4,
                },
                {
                  label: "审核方案管理人员",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "审核方案更新时间",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "受理意见",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "评审截止时间",
                  type: "text",
                  field: "review_11_limit_date",
                  cols: 4,
                },
              ],
              table: {
                type: "testitems_table",
                actions: ["expand"],
              },
            },
            {
              label: "初评",
              project_status: 13,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "项目申报声明",
                  step: 1,
                  tab: 0,
                  type: "file",
                  category: 2,
                },
                {
                  label: "评价详情",
                  step: 4,
                  tab: 1,
                  type: "table",
                },
              ],
              form: [
                {
                  label: "初评结论",
                  type: "radio",
                  project_status: 13,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "初评意见",
                  type: "input",
                  project_status: 13,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "初评人员",
                  type: "text",
                  project_status: 13,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "初评时间",
                  type: "text",
                  project_status: 13,
                  field: "update_time",
                  cols: 3,
                },
              ],
              table: {
                type: "testitems_table",
                header: [
                  {
                    text: "得分情况",
                    divider: true,
                    align: "center",
                    sortable: false,
                    width: "7%",
                  },
                ],
                actions: ["expand"],
              },
            },
            {
              label: "复核",
              project_status: 15,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "项目申报声明",
                  step: 1,
                  tab: 0,
                  type: "file",
                  category: 2,
                },
                {
                  label: "评价详情",
                  step: 4,
                  tab: 1,
                  type: "table",
                },
              ],
              form: [
                {
                  label: "初评结论",
                  project_status: 13,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "初评意见",
                  type: "text",
                  project_status: 13,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "初评人员",
                  project_status: 13,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "初评时间",
                  project_status: 13,
                  field: "update_time",
                  cols: 3,
                },
                {
                  label: "复评结论",
                  type: "radio",
                  project_status: 15,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "复评意见",
                  type: "input",
                  project_status: 15,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "复评人员",
                  type: "text",
                  project_status: 15,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "复评时间",
                  type: "text",
                  project_status: 15,
                  field: "update_time",
                  cols: 3,
                },
              ],
              table: {
                type: "testitems_table",
                header: [
                  {
                    text: "得分情况",
                    divider: true,
                    align: "center",
                    sortable: false,
                    width: "7%",
                  },
                ],
                actions: ["expand"],
              },
            },
            {
              label: "认证决定与批准",
              project_status: 17,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "项目申报声明",
                  step: 1,
                  tab: 0,
                  type: "file",
                  category: 2,
                },
                {
                  label: "评价详情",
                  step: 4,
                  tab: 1,
                  type: "table",
                },
              ],
              form: [
                {
                  label: "初评结论",
                  project_status: 13,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "初评意见",
                  project_status: 13,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "初评人员",
                  project_status: 13,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "初评时间",
                  project_status: 13,
                  field: "update_time",
                  cols: 3,
                },
                {
                  label: "复评结论",
                  type: "text",
                  project_status: 15,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "复评意见",
                  type: "text",
                  project_status: 15,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "复评人员",
                  type: "text",
                  project_status: 15,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "复评时间",
                  type: "text",
                  project_status: 15,
                  field: "update_time",
                  cols: 3,
                },
                {
                  label: "认证决定结论",
                  type: "radio",
                  project_status: 17,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "认证决定人员",
                  type: "text",
                  project_status: 17,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "认证决定时间",
                  type: "text",
                  project_status: 17,
                  field: "update_time",
                  cols: 3,
                },
              ],
              table: {
                type: "testitems_table",
                header: [
                  {
                    text: "得分情况",
                    divider: true,
                    align: "center",
                    sortable: false,
                    width: "7%",
                  },
                ],
                actions: ["expand"],
              },
            },
          ],
        },
        {
          title: "证后监督",
          tab: 0,
          tabs: [
            {
              label: "监督员分配",
              project_status: 30,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "证书状态",
                  step: 7,
                  tab: 0,
                  type: "form",
                },
              ],
              form: [
                {
                  label: "审核方案管理人员",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "监督任务分配时间",
                  type: "text",
                  field: "review_31_assign_date",
                  cols: 4,
                },
                {
                  label: "监督评价截止时间",
                  type: "date",
                  show: false,
                  cols: 4,
                },
              ],
              table: {
                type: "testitems_table",
                actions: ["select", "expand"],
              },
            },
            {
              label: "监督评价",
              project_status: 31,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "评价详情",
                  step: 4,
                  tab: 1,
                  type: "table",
                },
                {
                  label: "证书状态",
                  step: 7,
                  tab: 0,
                  type: "form",
                },
              ],
              form: [
                {
                  label: "审核方案管理人员",
                  type: "text",
                  cols: 4,
                },
                {
                  label: "监督任务分配时间",
                  type: "text",
                  field: "review_31_assign_date",
                  cols: 4,
                },
                {
                  label: "监督任务截止时间",
                  type: "text",
                  field: "review_31_limit_date",
                  cols: 4,
                },
              ],
              table: {
                type: "testitems_table",
                actions: ["expand"],
              },
            },
            {
              label: "监督初审",
              project_status: 33,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "评价详情",
                  step: 4,
                  tab: 1,
                  type: "table",
                },
                {
                  label: "证书状态",
                  step: 7,
                  tab: 0,
                  type: "form",
                },
              ],
              form: [
                {
                  label: "监督初评结论",
                  type: "radio",
                  project_status: 33,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "监督初评意见",
                  type: "input",
                  project_status: 33,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "监督初评人员",
                  type: "text",
                  project_status: 33,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "初评提交时间",
                  type: "text",
                  project_status: 33,
                  field: "update_time",
                  cols: 3,
                },
              ],
              table: {
                type: "testitems_table",
                header: [
                  {
                    text: "得分情况",
                    divider: true,
                    align: "center",
                    sortable: false,
                    width: "7%",
                  },
                ],
                actions: ["expand"],
              },
            },
            {
              label: "监督复核",
              project_status: 35,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "评价详情",
                  step: 4,
                  tab: 1,
                  type: "table",
                },
                {
                  label: "证书状态",
                  step: 7,
                  tab: 0,
                  type: "form",
                },
              ],
              form: [
                {
                  label: "监督初评结论",
                  type: "text",
                  project_status: 33,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "监督初评意见",
                  type: "text",
                  project_status: 33,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "监督初评人员",
                  type: "text",
                  project_status: 33,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "初评提交时间",
                  type: "text",
                  project_status: 33,
                  field: "update_time",
                  cols: 3,
                },
                {
                  label: "监督复评结论",
                  type: "radio",
                  project_status: 35,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "监督复评意见",
                  type: "input",
                  project_status: 35,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "监督复评人员",
                  type: "text",
                  project_status: 35,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "监督复评时间",
                  type: "text",
                  project_status: 35,
                  field: "update_time",
                  cols: 3,
                },
              ],
              table: {
                type: "testitems_table",
                header: [
                  {
                    text: "得分情况",
                    divider: true,
                    align: "center",
                    sortable: false,
                    width: "7%",
                  },
                ],
                actions: ["expand"],
              },
            },
            {
              label: "监督认证决定",
              project_status: 37,
              link: [
                {
                  label: "建筑基本信息",
                  step: 1,
                  tab: 0,
                  type: "form",
                },
                {
                  label: "评价详情",
                  step: 4,
                  tab: 1,
                  type: "table",
                },
                {
                  label: "证书状态",
                  step: 7,
                  tab: 0,
                  type: "form",
                },
              ],
              form: [
                {
                  label: "监督初评结论",
                  type: "text",
                  project_status: 33,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "监督初评意见",
                  type: "text",
                  project_status: 33,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "监督初评人员",
                  type: "text",
                  project_status: 33,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "初评提交时间",
                  type: "text",
                  project_status: 33,
                  field: "update_time",
                  cols: 3,
                },
                {
                  label: "监督复评结论",
                  type: "text",
                  project_status: 35,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "监督复评意见",
                  type: "text",
                  project_status: 35,
                  field: "test_text",
                  cols: 3,
                },
                {
                  label: "监督复评人员",
                  type: "text",
                  project_status: 35,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "监督复评时间",
                  type: "text",
                  project_status: 35,
                  field: "update_time",
                  cols: 3,
                },
                {
                  label: "监督认证决定结论",
                  type: "radio",
                  project_status: 37,
                  field: "test_result",
                  cols: 3,
                },
                {
                  label: "监督认证决定人员",
                  type: "text",
                  project_status: 37,
                  field: "expert",
                  cols: 3,
                },
                {
                  label: "监督认证决定时间",
                  type: "text",
                  project_status: 37,
                  field: "update_time",
                  cols: 3,
                },
              ],
              table: {
                type: "testitems_table",
                header: [
                  {
                    text: "得分情况",
                    divider: true,
                    align: "center",
                    sortable: false,
                    width: "7%",
                  },
                ],
                actions: ["expand"],
              },
            },
          ],
        },
	      {
		      title: "证书状态",
		      tab: 0,
		      project_status: 20,
		      tabs: [
			      {
				      label: "证书状态",
				      project_status: 20,
				      link: [
					      {
						      label: "建筑基本信息",
						      step: 1,
						      tab: 0,
						      type: "form",
			          },
			          {
				          label: "项目申报声明",
				          step: 1,
                  tab: 0,
                  type: "file",
                  category: 2,
                },
                {
                  label: "评价详情",
                  step: 4,
                  tab: 1,
                  type: "table",
                },
              ],
              form: [
	              {
		              label: "证书编号",
		              project_status: 20,
		              require: true,
		              type: "text",
		              field: "test_detail_score",
		              cols: 4,
	              },
	              {
		              label: "证书等级",
		              project_status: 20,
		              require: true,
		              type: "radio",
		              field: "test_value",
		              cols: 4,
	              },
	              {
		              label: "证书有效期",
		              project_status: 20,
		              require: true,
		              type: "date",
		              show: false,
		              field: "test_time",
		              cols: 4,
	              },
	              {
		              label: "证书状态",
		              project_status: 20,
		              require: true,
		              type: "text",
		              field: "test_score",
		              cols: 4,
	              },
	              {
		              label: "证书处理",
		              project_status: 20,
		              require: true,
		              type: "select",
		              field: "test_score",
		              cols: 4,
	              },
	              {
		              label: "证书处理人员",
		              project_status: 20,
		              require: true,
		              type: "text",
		              field: "expert",
		              cols: 4,
	              },
              ],
              certificate: "123",
            },
          ],
        },
      ],
      //链接弹窗数据
      linkDialog: {
        show: false,
        type: "",
        data: {},
      },
      //基础信息数据
      projectInfo: {
        files: [],
      },
      //字典列表
      typeList: [],
      //建筑类型列表
      buildingTypeList: [],
      //专家列表
      expertList: [],
      //评审项列表
      testitemList: [],
      //已分配专家
      assignedList: {},
      //专家分配时设置的评价截止时间
      assign_limit_date: {
        assign_10: null,
        assign_30: null,
      },
      //评审决定（初审/复审/评审决定）
      reviewDecision: {
        review_1: {
          test_text: null,
          test_result: null,
          update_time: null,
          expert: null,
        },
        review_13: {
          test_text: null,
          test_result: null,
          update_time: null,
          expert: null,
        },
        review_15: {
          test_text: null,
          test_result: null,
          update_time: null,
          expert: null,
        },
        review_17: {
          test_text: null,
          test_result: null,
          update_time: null,
          expert: null,
        },
        review_33: {
          test_text: null,
          test_result: null,
          update_time: null,
          expert: null,
        },
        review_35: {
          test_text: null,
	        test_result: null,
	        update_time: null,
	        expert: null,
        },
	      review_37: {
		      test_text: null,
		      test_result: null,
		      update_time: null,
		      expert: null,
	      },
	      review_20: {
		      test_text: null,
		      test_time: null,
		      test_value: null,
		      test_result: null,
		      test_score: null,
		      update_time: null,
		      expert: null,
	      },
      },
      //证书页面，证书处理按钮
	    certificateHandle: [
		    {
			    value: 1,
			    text: "签发",
		    },
		    {
			    value: 2,
			    text: "暂停",
		    },
		    {
			    value: 3,
          text: "恢复",
        },
        {
          value: 4,
          text: "注销",
        },
      ],
      //证书页面，证书变更弹窗
      certificateDialog: {
        show: false,
      },
    };
  },
  watch: {
    //监听选中的筛选条件，若变动则更新表格数据
    filters_selected: {
      handler() {
        this.doFilter();
      },
      deep: true,
    },
    //监听一级分类筛选，若变化则联动更新二级筛选项
    "filters_selected.lvl_1"(val) {
      let lvl_2 = [];
      if (val) {
        this.filters.lvl_2_bak.map((item) => {
          if (val.includes(item.parent)) {
            lvl_2.push(item);
          }
        });
      } else {
        lvl_2 = this.filters.lvl_2_bak;
      }
      this.filters.lvl_2 = lvl_2;
    },
  },
  methods: {
	  ...mapMutations({
		  setProjectInfo: "projectManage/SETPROJECT_INFO",
		  setTypeList: "projectManage/SETTYPELIST",
		  setExpertList: "projectManage/SETEXPERTLIST",
		  setTestItemList: "projectManage/SETTESTITEMLIST",
	  }),
	  //证书状态变更
	  certificateChange() {
		  // this.certificateDialog.show = true;
		  let review_20 = this.reviewDecision.review_20
		  this.reviewDecision.review_20['test_detail_score'] = review_20['test_detail_score'] ? review_20['test_detail_score'] : this.certificateNumber();
		  // console.log('certificateChange',this.reviewDecision.review_20)
		  this.submit("submit")
		  this.certificateDialog.show = false
	  },
	  //生成证书编号
	  certificateNumber() {
		  return 'certificate_' + (new Date().valueOf()) + Math.floor(Math.random() * 10000)
	  },
	  //获取证书处理文字
	  getCertificateHandle(value) {
		  let text = ''
		  this.certificateHandle.map(item => {
			  if (item.value == value) {
				  text = item.text
			  }
		  })
		  return text ? text : '暂无数据'
	  },
	  /*** 用户权限相关----------------------start */
	  //判断当前登陆用户角色
	  groupType(type) {
		  return this.user.groups.includes(type);
	  },
	  //判断当前用户可以访问的页面
	  group_page_permission() {
		  let permissions = [
			  {
				  group: "Admins",
          page: [
            { step: 1, tabs: [0, 1] },
            { step: 2, tabs: [0] },
            { step: 3, tabs: [0] },
            { step: 4, tabs: [0, 1, 2, 3, 4] },
            { step: 5, tabs: [0, 1, 2, 3, 4] },
            { step: 6, tabs: [0] },
          ],
        },
        {
          group: "企业用户",
          page: [
	          {step: 1, tabs: [0, 1]},
	          {step: 2, tabs: [0], form: [true]},
	          {step: 3, tabs: [0], form: [true]},
	          {step: 6, tabs: [0], form: [true]},
          ],
        },
        {
          group: "CQC管理员（认证受理）",
          page: [
            { step: 2, tabs: [0] },
            { step: 3, tabs: [0] },
          ],
        },
        {
          group: "CQC管理员（专家分配）",
          page: [
            { step: 4, tabs: [0] },
            { step: 5, tabs: [0] },
          ],
        },
        { group: "专家（评价）", page: [{ step: 4, tabs: [1] }] },
        { group: "专家（评价-初审）", page: [{ step: 4, tabs: [2] }] },
        { group: "专家（评价-复审）", page: [{ step: 4, tabs: [3] }] },
        {
          group: "专家（评价-认定批准）",
          page: [
            { step: 4, tabs: [4] },
            { step: 6, tabs: [0] },
          ],
        },
        { group: "专家（监督）", page: [{ step: 5, tabs: [1] }] },
        { group: "专家（监督-初审）", page: [{ step: 5, tabs: [2] }] },
        { group: "专家（监督-复审）", page: [{ step: 5, tabs: [3] }] },
        {
          group: "专家（监督-认定批准）",
          page: [
            { step: 5, tabs: [4] },
            { step: 6, tabs: [0] },
          ],
        },
      ];
      //获取满足对group
      let arr = permissions.filter((permissionItem) =>
        this.user.groups.includes(permissionItem.group)
      );
      let steps = {
        steps: [],
      };
      arr.map((item) => {
        item.page.map((step) => {
          let stepKey = "step_" + step.step;
          if (steps[stepKey]) {
	          let new_tabs = Array.from(
			          new Set(steps[stepKey].tabs.concat(step.tabs))
	          );
	          let new_form = Array.from(
			          new Set(steps[stepKey].forms.concat(step.form))
	          );
	          steps[stepKey].tabs = new_tabs;
	          steps[stepKey].form = new_form;
	          steps[stepKey].tab = Math.min(...new_tabs);
          } else {
	          steps[stepKey] = step;
	          steps[stepKey].tab = Math.min(...step.tabs);
          }
	        steps["steps"].push(step.step);
        });
      });
		  this.page_permissions = steps;
		  this.step = Math.min(...steps["steps"]);
		  steps["steps"].map((step) => {
			  this.stepPageList[step - 1].tab = Math.min(
					  ...steps["step_" + step].tabs
			  );
		  });
		  console.log('page_permissions', steps)
		  return steps;
	  },
    /*** 用户权限相关----------------------end */

    /*** 文件上传相关----------------------start */
    //文件筛选:返回文件下标
    file_handle(category) {
      let list = [];
      if (this.projectInfo.files) {
        this.projectInfo.files.map((item, index) => {
          if (item.prev_file.category === category) {
            list.push(index);
          }
        });
      }
      return list;
    },
    //上传文件-新增
    file_add(category) {
      if (category <= 10) {
        this.projectInfo.files.push({
          media_file: { category: category, name: "" },
          prev_file: { category: category, name: "" },
        });
      }
      //console.log('length', this.file_handle(category).length)
    },
    //上传文件-移除
    file_remove(category, fileIndex) {
      let media_id;
      if (category <= 10) {
        media_id = this.projectInfo.files[fileIndex].media_file.id;
        this.projectInfo.files.splice(fileIndex, 1);
      }
      //确认删除
      if (confirm("是否确认删除？")) {
        removeMedia(media_id);
      }
    },
    //上传文件-预览和下载
    file_preview(file) {
      if (file.mediaurl) {
        window.open("https://" + file.mediaurl);
      } else {
        //console.log('file', file)
      }
    },
    //上传文件-上传
    file_upload(category, fileIndex) {
      // console.log('file_upload_category', category)
      // console.log('file_upload_fileIndex', fileIndex)
      let obj;
      //category <= 10,即项目信息页上传文件
      if (category <= 10) {
        obj = this.projectInfo.files[fileIndex];
      }
      //category = 11,自评价上传数据;
      if (category === 11) {
        obj = this.testitems_table.formdata[fileIndex]["review_0"].file;
      }
      //category = 12,专家评价上传数据;
      if (category === 12) {
        obj = this.testitems_table.formdata[fileIndex]["review_11"].file;
      }
      let _this = this;

      obj.loading = true;
      obj.progress = 0;

      this.cos.putObject(
        {
          Bucket: "cqc-1255710621", // 存储桶名称。必须字段。
          Region: "ap-beijing", // 存储桶所在地域，必须字段。
          Key: `/projects/${_this.id}/${obj.media_file.name}`, // 上传后的路径及文件名。必须。 */
          StorageClass: "STANDARD",
          Body: obj.media_file, // 准备上传的本地文件对象。必须字段。
          onProgress: function(progressData) {
            if (progressData.percent === 1 || progressData.percent === "1") {
              obj.loading = false;
            }
            obj.progress = progressData.percent * 100;
            //console.log(JSON.stringify(progressData));
          },
        },
        function(err, data) {
          if (err) {
            //console.log(err);
          } else {
            //console.log("mediaurl", data.Location);

            // 如果是重传的文件，则需要更新 Media 表中的记录。
            if (obj.prev_file.id) {
              let update_data = {
                id: obj.prev_file.id,
                project: _this.id,
                name: obj.media_file.name,
                mediaurl: data.Location,
                category: category,
              };
              updateMedia(update_data)
                .then((result) => {
                  //console.log('end_upload_2', result)
                  obj.media_file = result;
                  obj.prve_file = result;
                  //category = 11,自评价上传数据;
                  if (category === 11) {
                    _this.testitems_table.formdata[fileIndex][
                      "review_0"
                    ].test_files[0] = result;
                  }
                  //category = 12,专家评价上传数据;
                  if (category === 12) {
                    _this.testitems_table.formdata[fileIndex][
                      "review_11"
                    ].test_files[0] = result;
                  }
                  _this.submit();
                })
                .catch((err) => {
                  alert(err);
                });
            }
            // 如果不是重传的文件，则需要在 Media 表中添加一条记录。
            else {
              addMedia({
                name: obj.media_file.name,
                mediaurl: data.Location,
                project: _this.id,
                category: category,
              })
                .then((result) => {
                  //console.log('end_upload_1', result)
                  obj.media_file = result;
                  obj.prve_file = result;
                  //category = 11,自评价上传数据;
                  if (category === 11) {
                    _this.testitems_table.formdata[fileIndex][
                      "review_0"
                    ].test_files[0] = result;
                  }
                  //category = 12,专家评价上传数据;
                  if (category === 12) {
                    _this.testitems_table.formdata[fileIndex][
                      "review_11"
                    ].test_files[0] = result;
                  }
                  _this.submit();
                })
                .catch((err) => {
                  alert(err);
                });
            }
          }
        }
      );
    },
    /*** 文件上传相关----------------------end */

    /*** 数据提交：保存（暂存）/提交（保存并提交审核）当前页面的数据  start*/
    async submit(type = "save") {
      let stepIndex = this.step - 1;
      let stepPage = this.stepPageList[stepIndex];
      let tabIndex = stepPage.tab;
      let tab = stepPage.tabs[tabIndex];
      //提交post的数据格式
      let postData = {
        project: this.id,
        review_no: this.projectInfo.review_no,
        project_status: tab.project_status,
        review_type: 1,
        results: [],
      };
      //项目基本信息更新
      if (tab.project_status === 0 && stepIndex === 0 && tabIndex === 0) {
	      let postData = JSON.parse(JSON.stringify(this.projectInfo));
	      postData.project_status = this.projectInfo.project_status.value
	      console.log('status', postData.project_status)
	      updateProject(postData)
			      .then(() => {
				      this.getProjectInfo();
			      })
			      .catch((error) => {
				      alert("保存失败！错误信息：" + error);
			      });
      }
      //保存表格中各指标评价的数据
      else if ([0, 11, 31].includes(tab.project_status)) {
        //循环处理表单数据
        this.testitems_table.formdata.map((item) => {
          let data = JSON.parse(
            JSON.stringify(item["review_" + tab.project_status])
          );
          let data_bak = JSON.parse(
            JSON.stringify(item["review_bak_" + tab.project_status])
          );

          let fieldArr = [
            "test_score",
            "test_result",
            "test_text",
            "test_value",
            "base_status",
          ];
          //监督数据为数组，单独处理
          if (tab.project_status === 31) {
            data.map((item_1, index_1) => {
              let dataItem = {};
              //处理上传的文件（只上传media_id）
              item_1.test_files =
                item_1.test_files[0] && item_1.test_files[0].id
                  ? [item_1.test_files[0].id]
                  : [];
              data_bak[index_1].test_files =
                data_bak[index_1].test_files[0] &&
                data_bak[index_1].test_files[0].id
                  ? [data_bak[index_1].test_files[0].id]
                  : [];
              //比较各字段数据是否变化
              fieldArr.map((field) => {
                if (item_1[field] !== data_bak[index_1][field]) {
                  dataItem[field] = JSON.parse(JSON.stringify(item_1[field]));
                }
              });
              //比较'test_files'字段数据是否变化
              if (
                item_1["test_files"].length &&
                item_1["test_files"][0] !== data_bak[index_1]["test_files"][0]
              ) {
                dataItem["test_files"] = JSON.parse(
                  JSON.stringify(item_1["test_files"])
                );
              }
              //若数据不为空数组，将数据保存进待post数组
              if (Object.keys(dataItem).length) {
                postData.results.push({
                  ...dataItem,
                  test_item: item_1.test_item,
                  review_id: item_1.id,
                  expert: item_1.expert ? item_1.expert : this.user.id,
                });
              }
            });
          } else {
            //若数据变化时，加入待提交待数组
            let dataItem = {};
            //处理上传的文件（只上传media_id）
            data.test_files =
              data.test_files[0] && data.test_files[0].id
                ? [data.test_files[0].id]
                : [];
            data_bak.test_files =
              data_bak.test_files[0] && data_bak.test_files[0].id
                ? [data_bak.test_files[0].id]
                : [];

            //比较各字段数据是否变化
            fieldArr.map((field) => {
              if (data[field] !== data_bak[field]) {
                dataItem[field] = JSON.parse(JSON.stringify(data[field]));
              }
            });
            //比较'test_files'字段数据是否变化
            if (
              data["test_files"].length &&
              data["test_files"][0] !== data_bak["test_files"][0]
            ) {
              dataItem["test_files"] = JSON.parse(
                JSON.stringify(data["test_files"])
              );
            }
            //若数据不为空数组，将数据保存进待post数组
            if (Object.keys(dataItem).length) {
              postData.results.push({
                ...dataItem,
                test_item: data.test_item,
                review_id: data.id,
                expert: data.expert ? data.expert : this.user.id,
              });
            }
          }
        });
        //console.log('postData', postData)
        if (postData.results.length) {
          //更新评审项
          addReview(postData)
            .then(() => {
              this.getReviews();
	            alert("数据保存成功！");
            })
		          .catch((error) => {
			          alert("提交失败！错误信息：" + error);
		          });
        } else {
	        alert("没有新的数据需要提交！");
        }
      }
      //初评/复审/认证评价的数据
      else if ([1, 13, 15, 17, 33, 35, 37, 20].includes(tab.project_status)) {
	      let data = this.reviewDecision["review_" + tab.project_status];
	      postData.review_type = 2;
	      postData.results[0] = {
		      review_id: data.review_id,
		      test_result: data.test_result,
		      test_detail_score: data.test_detail_score,
		      test_value: data.test_value,
		      test_score: data.test_score,
		      test_time: this.dateFormat(data.test_time, 'long'),
		      test_text: data.test_text,
		      expert: this.user.id,
	      };
        //更新评审项
        addReview(postData)
          .then(() => {
            this.getReviews();
            alert("数据保存成功！");
          })
          .catch((error) => {
            alert("提交失败！错误信息：" + error);
          });
      }
      //专家分配提交数据
      else if ([10, 30].includes(tab.project_status)) {
        let postData = {
          project: this.id,
          project_status: tab.project_status,
          assignments: [],
        };
        let limit_date = this.assign_limit_date["assign_" + tab.project_status];
        limit_date = limit_date ? this.dateFormat(limit_date) : null;
        Object.values(this.assignedList).map((item) => {
          if (item["assign_" + tab.project_status].user) {
            item["assign_" + tab.project_status]["limit_date"] = limit_date;
            postData.assignments.push(
              JSON.parse(JSON.stringify(item["assign_" + tab.project_status]))
            );
          }
        });
        //console.log("postData", postData);
        addTestItemAssignments(postData)
          .then(() => {
            this.getTestItemAssignments();
            alert("数据保存成功！");
          })
          .catch((error) => {
            alert("分配方案提交失败！错误信息：" + error);
          });
      }
      //若为提交，则更新project_status
      if (type === "submit") {
	      console.log('project_status', this.projectInfo)
	      if (confirm("是否确定提交数据？")) {
		      let new_project_status = 0;
		      //评价数据提交
		      if ([0, 11, 31, 1, 2, 13, 15, 33, 35].includes(tab.project_status)) {
			      new_project_status = tab.project_status + 1;
		      }
		      //评审认定提交
		      else if ([17, 37, 20].includes(tab.project_status)) {
			      new_project_status = 20;
		      }
		      //专家分配提交
		      else if ([10, 30].includes(tab.project_status)) {
			      new_project_status = tab.project_status;
		      }
		      if (new_project_status > this.projectInfo.project_status.value) {
			      updateProject({
				      id: this.id,
				      project_status: new_project_status,
			      })
					      .then(() => {
						      this.getProjectInfo();
						      alert("数据已提交！已通知管理员审核。");
					      })
					      .catch((error) => {
						      alert("保存失败！错误信息：" + error);
					      });
		      }
	      }
      }
    },
    /*** 数据提交：保存（暂存）/提交（保存并提交审核）当前页面的数据  end*/

    /*** 表格相关----------------------start */
    //数据筛选
    doFilter() {
      let formdata = [];
      //初始化数据
      this.testitems_table.formdata_bak.map((item) => {
        //若二级分类有值，直接筛选二级分类
        if (this.filters_selected.lvl_2.length > 0) {
          if (this.filters_selected.lvl_2.includes(item.lvl_2)) {
            formdata.push(item);
          }
          //若二级分类为空，那么筛选一级分类
        } else if (this.filters_selected.lvl_1.length > 0) {
          if (this.filters_selected.lvl_1.includes(item.lvl_1)) {
            formdata.push(item);
          }
        }
        if (this.filters_selected.lvl_1.length === 0) {
          formdata.push(item);
        }
      });
      this.testitems_table.formdata = formdata;
    },
    //表头处理
    testitems_table_headers_handlers(expand_headers, actions) {
      let expand = [
        {
          text: "",
          value: "data-table-expand",
          align: "center",
          divider: true,
          sortable: false,
          width: "1%",
        },
      ];
      let select = [
        {
          text: "",
          value: "data-table-select",
          align: "center",
          divider: true,
          sortable: false,
          width: "1%",
        },
      ];
      let default_headers = JSON.parse(
        JSON.stringify(this.testitems_table.headers)
      );
      let headers = expand_headers
        ? default_headers.concat(expand_headers)
        : default_headers;
      if (actions && actions.includes("expand")) {
        headers = headers.concat(expand);
      }
      if (actions && actions.includes("select")) {
        headers = headers.concat(select);
      }
      return headers;
    },
    //专家分配选中改变事件
    expert_selected_change(test_item, project_status, value) {
      //表格多选
      if (this.testitems_table.selected.length > 0) {
        if (confirm("是否对选中的行批量分配专家？")) {
          this.testitems_table.selected.map((item) => {
            this.assignedList[item.test_item]["assign_" + project_status][
              "user"
            ] = value;
          });
          this.$forceUpdate();
        }
      } else {
        this.assignedList[test_item]["assign_" + project_status][
          "user"
        ] = value;
      }
    },
    //监督评价新增
    review_31_add(test_item) {
      let index = this.testitems_table.formdata_keys.indexOf(test_item + "");
      let data = {
        project: this.id,
        test_item: test_item,
        project_status: 31,
        test_value: null,
        test_text: null,
        test_result: null,
        test_score: null,
        expert:
          this.assignedList[test_item] &&
          this.assignedList[test_item]["assign_30"].user
            ? this.assignedList[test_item]["assign_30"].user
            : null,
        update_time: null,
        base_status: true,
        test_files: [{ mediaurl: "", name: "" }],
        review_type: 1,
        file: {
          media_file: { mediaurl: "", name: "" },
          prev_file: { mediaurl: "", name: "" },
          loading: false,
          progress: 0,
        },
      };
      this.testitems_table.formdata[index]["review_31"].push(
        JSON.parse(JSON.stringify(data))
      );
      this.testitems_table.formdata[index]["review_bak_31"].push(
        JSON.parse(JSON.stringify(data))
      );
    },
    //监督评价删除
    review_31_remove(test_item, review_31_index) {
      if (confirm("是否确认删除这条数据？")) {
        //console.log('remove', [test_item, review_31_index])
        let index = this.testitems_table.formdata_keys.indexOf(test_item + "");
        this.testitems_table.formdata[index]["review_31"][
          review_31_index
        ].base_status = false;
        //console.log('remove', this.testitems_table.formdata[index]['review_31'])
      }
    },
    /*** 表格相关----------------------end */

    /*** 页面切换相关----------------------start */
    changeTab(type) {
      let step = this.step;
      let tab = this.stepPageList[step - 1].tab;
      let newTab = tab + type;
      if (
        newTab >= 0 &&
        newTab < this.stepPageList[step - 1].tabs.length &&
        this.page_permissions["step_" + step].tabs.includes(newTab)
      ) {
        this.$set(this.stepPageList[step - 1], "tab", tab + type);
      }
    },
    changeStep(type) {
      let newStep = this.step + type;
      if (
        newStep >= 1 &&
        newStep <= this.stepPageList.length &&
        this.page_permissions.steps.includes(newStep)
      ) {
        this.step = newStep;
      }
    },
    /*** 页面切换相关----------------------end */

    /*** 页面数据初始化相关----------------------start */
    //初始化表格
    testitems_table_data_init: function() {
      let project_status_list = [0, 11, 31];
      let form_data = {};
      let assign_data = {};
      let index = 0;
      let data = {
        project: this.id,
        test_item: null,
        test_value: null,
        test_text: null,
        test_result: null,
        test_score: null,
        expert: null,
        update_time: null,
        base_status: true,
        test_files: [{ mediaurl: "", name: "" }],
        review_type: 1,
        file: {
          media_file: { mediaurl: "", name: "" },
          prev_file: { mediaurl: "", name: "" },
          loading: false,
          progress: 0,
        },
      };

      this.testitemList.map((lvl_1) => {
        //生成一级分类筛选
        this.filters.lvl_1.push({ text: lvl_1.name, value: lvl_1.name });
        lvl_1.children.map((lvl_2) => {
          //生成二级分类筛选
          this.filters.lvl_2.push({
            text: lvl_2.name,
            value: lvl_2.name,
            parent: lvl_1.name,
          });
          this.filters.lvl_2_bak.push({
            text: lvl_2.name,
            value: lvl_2.name,
            parent: lvl_1.name,
          });
          lvl_2.children.map((lvl_3) => {
            data.test_item = lvl_3.id;
            form_data[lvl_3.id] = {
              //表格展示数据
              index: index,
              lvl_1: lvl_1.name,
              lvl_2: lvl_2.name,
              name: lvl_3.name,
              code: lvl_3.code,
              unit: lvl_3.unit,
              type: lvl_3.type,
              score: lvl_3.score,
              test_item: lvl_3.id,
              //是否参评
              active: true,
            };
            //评价绑定数据
            project_status_list.map((statusItem) => {
              if (statusItem === 31) {
                form_data[lvl_3.id]["review_" + statusItem] = [
                  {
                    ...JSON.parse(JSON.stringify(data)),
                    project_status: statusItem,
                  },
                ];
                form_data[lvl_3.id]["review_bak_" + statusItem] = [
                  {
                    ...JSON.parse(JSON.stringify(data)),
                    project_status: statusItem,
                  },
                ];
              } else {
                form_data[lvl_3.id]["review_" + statusItem] = {
                  ...JSON.parse(JSON.stringify(data)),
                  project_status: statusItem,
                };
                form_data[lvl_3.id]["review_bak_" + statusItem] = {
                  ...JSON.parse(JSON.stringify(data)),
                  project_status: statusItem,
                };
              }
            });
            //分家分配绑定数据
            assign_data[lvl_3.id] = {
              assign_10: {
                project: this.id,
                test_item: lvl_3.id,
                user: null,
                project_status: 10,
              },
              assign_30: {
                project: this.id,
                test_item: lvl_3.id,
                user: null,
                project_status: 30,
              },
            };
            index += 1;
          });
        });
      });
      // console.log('formdata', form_data)
      form_data = JSON.parse(JSON.stringify(form_data));
      this.testitems_table.formdata = Object.values(form_data);
      this.testitems_table.formdata_bak = Object.values(form_data);
      this.testitems_table.formdata_keys = Object.keys(form_data);
      this.assignedList = assign_data;
      //console.log("assign_init", this.assignedList);
    },
    //初始化表单
    review_decision_data_init: function() {
	    let project_status_list = [1, 13, 15, 17, 33, 35, 37, 20];
	    let form_data = {};
      let data = {
	      project: this.id,
	      test_item: null,
	      test_value: null,
	      test_text: null,
	      test_result: null,
	      test_score: null,
	      test_time: null,
	      test_detail_score: null,
	      expert: null,
	      update_time: null,
	      test_files: [{mediaurl: "", name: ""}],
	      review_type: 2,
	      file: {
		      media_file: {mediaurl: "", name: ""},
		      prev_file: {mediaurl: "", name: ""},
		      loading: false,
		      progress: 0,
	      },
      };
	    project_status_list.map((statusItem) => {
		    form_data["review_" + statusItem] = {
			    ...JSON.parse(JSON.stringify(data)),
			    project_status: statusItem,
		    };
	    });
	    console.log('reviewDecision', form_data)
	    this.reviewDecision = form_data;
    },
	  // 获取项目基础信息
	  async getProjectInfo() {
		  await this.getReviews()
		  getProjectInfo(this.id).then((res) => {
			  res.update_time = this.dateFormat(res.update_time);
			  let files = [];
			  //对文件进行备份
			  res.files.map((item) => {
				  let file = JSON.parse(JSON.stringify(item));
				  files.push({media_file: file, prev_file: file});
			  });
			  res.files = files;
			  res.review_system = res.review_system.value
			  this.projectInfo = res;
			  this.setProjectInfo(res);
			  this.update_project_status();
			  console.log("projectInfo", res);
			  localStorage.setItem("projectInfo", JSON.stringify(res));
		  });

    },
    //获取已打分的评审
    getReviews() {
      getReviews({ project: this.id, pagesize: 100000 }).then((res) => {
        let review_31_arr = [];
        let formdata_keys = this.testitems_table.formdata_keys;
        res.results.map((reviewItem) => {
	        reviewItem.update_time = this.dateFormat(reviewItem.update_time, 'short');
	        reviewItem.test_time = this.dateFormat(reviewItem.test_time, 'short');
	        reviewItem.test_score = parseInt(reviewItem.test_score);
	        reviewItem.review_id = reviewItem.id;
	        //针对每条指标的评价：reviewItem.review_type===1
	        if (reviewItem.review_type === 1 && reviewItem.test_item) {
		        let index = formdata_keys.indexOf(reviewItem.test_item + "");
		        let formDataItem = {
			        ...JSON.parse(JSON.stringify(reviewItem)),
			        file: {
				        media_file: reviewItem.test_files[0]
						        ? reviewItem.test_files[0]
						        : {mediaurl: "", name: ""},
                prev_file: reviewItem.test_files[0]
                  ? reviewItem.test_files[0]
                  : { mediaurl: "", name: "" },
                loading: false,
                progress: 0,
              },
            };
            //监督评价的数据为数组
            if (reviewItem.project_status === 31) {
              //清除空数据
              this.testitems_table.formdata[index]["review_31"].map(
                (item_1, index_1) => {
                  if (!item_1.id) {
                    this.testitems_table.formdata[index]["review_31"].splice(
                      index_1,
                      1
                    );
                    this.testitems_table.formdata[index][
                      "review_bak_31"
                    ].splice(index_1, 1);
                  }
                }
              );
              let review_31_ids = this.testitems_table.formdata[index][
                "review_31"
              ].map((item) => item.id);
              let review_index = review_31_ids.indexOf(reviewItem.id);
              //若review_id存在则替换
              if (review_index >= 0) {
                this.testitems_table.formdata[index]["review_31"][
                  review_index
                ] = JSON.parse(JSON.stringify(formDataItem));
                this.testitems_table.formdata[index]["review_bak_31"][
                  review_index
                ] = JSON.parse(JSON.stringify(formDataItem));
              }
              //不存在则新增
              else {
                this.testitems_table.formdata[index]["review_31"].push(
		                JSON.parse(JSON.stringify(formDataItem))
                );
	              this.testitems_table.formdata[index]["review_bak_31"].push(
			              JSON.parse(JSON.stringify(formDataItem))
	              );
              }

	            // review_31_arr[index].push(JSON.parse(JSON.stringify(formDataItem)))

	            //console.log('review_31', this.testitems_table.formdata[index]["review_31"])
            } else {
	            this.$set(
			            this.testitems_table.formdata[index],
			            "review_" + reviewItem.project_status,
			            JSON.parse(JSON.stringify(formDataItem))
	            );
	            this.$set(
			            this.testitems_table.formdata[index],
			            "review_bak_" + reviewItem.project_status,
			            JSON.parse(JSON.stringify(formDataItem))
	            );
            }
          } else {
            //针对所有指标的评价：reviewItem.review_type===2
            this.reviewDecision["review_" + reviewItem.project_status] = {
              ...JSON.parse(JSON.stringify(reviewItem)),
              file: {
                media_file: reviewItem.test_files[0],
                prev_file: reviewItem.test_files[0],
                loading: false,
                progress: 0,
              },
            };
          }
        });
      });
    },
    //获取已分配的专家
    getTestItemAssignments() {
      getTestItemAssignments({
        project: this.id,
        pagesize: 100000,
      }).then((res) => {
        res.results.map((item) => {
          this.assignedList[item.test_item.id]["assign_10"]["user"] = item.user;
          this.assignedList[item.test_item.id]["assign_30"]["user"] = item.user;
          let index = this.testitems_table.formdata_keys.indexOf(
            item.test_item.id
          );
          //评审的已分配专家
          if (item.project_status === 11) {
            this.testitems_table.formdata[index].review_11.expert = item.user;
          }
          if (item.project_status === 31) {
            this.testitems_table.formdata[index].review_31.map(
              (review_31_item, review_31_index) => {
	              this.testitems_table.formdata[index].review_31[review_31_index][
			              "expert"
			              ] = item.user;
              }
            );
          }
	        this.assign_limit_date[
	        "assign_" + item.project_status
			        ] = this.dateFormat(item.limit_date, "short");
        });
	      //console.log('this.assignedList',this.assignedList)
	      this.$forceUpdate();
      });
    },
    /*** 页面数据初始化相关----------------------end */

    /*** 辅助函数----------------------start */
    // 显示链接页面
    link_show(step, tab, key) {
      this.linkDialog.data = this.stepPageList[step - 1].tabs[tab];
      this.linkDialog.type = key;
      //console.log("dialog", this.linkDialog);
      this.linkDialog.show = true;
    },
    //根据字典类型获取字典列表
    typeListHandle(type) {
      let list = [];
      if (type === "building_type") {
        this.buildingTypeList.map((item) => {
          list.push({ text: item.name, value: item.id });
        });
      } else if (type === "sub_building_type") {
        let building_type = this.projectInfo.building_type;
        this.buildingTypeList.map((item) => {
          if (item.id === building_type) {
            item.children.map((item2) => {
              list.push({ text: item2.name, value: item2.id });
            });
          }
        });
      } else {
        this.typeList.map((item) => {
          if (item.key === type) {
            list.push(item);
          }
        });
      }
      return list ? list : [];
    },
    //格式化时间
    dateFormat(date, type = "long") {
      let format = "YYYY-MM-DD HH:mm:ss";
      if (type === "short") {
        format = "YYYY-MM-DD";
      }
      return moment(date ? date : new Date()).format(format);
    },
    //日期选择器允许选择的日期
    allowedDates: (val) => moment(val).valueOf() > moment(new Date()).valueOf(),
    //获取媒体
    getMediaObject(category) {
      let files = this.projectInfo.files.filter((fileItem) => {
        return fileItem.media_file.category === category;
      });
      return files;
    },
    //表格保存/提交按钮显隐
    saveBtnShow() {
      let step = this.step;
      let tab = this.stepPageList[step - 1].tab;
      let showItem = step + "_" + tab;
      let showArr = [
        "1_0",
        "1_1",
        "2_0",
        "3_0",
	      "4_0",
	      "4_1",
	      "5_0",
	      "5_1",
	      "4_2",
	      "4_3",
	      "4_4",
	      "5_2",
	      "5_3",
	      "5_4",
	      "6_0",
      ];
      return showArr.includes(showItem);
    },
    //根据id获取用户姓名
    getUserName(id) {
      let expert = this.expertList.filter((item) => {
        return id === item.id;
      });
      return expert[0] && expert[0].username ? expert[0].username : "暂无数据";
    },
    //获取用户组
    getUserGroup(type) {
      return this.expertList.filter((item) => {
        return item.groups.includes(type);
      });
    },
    getTotalScore() {
      let total = {
        testitem: 0,
        review_0: 0,
        review_11: 0,
      };
      this.testitems_table.formdata.map((formItem) => {
	      total.testitem += parseInt(formItem.score);
	      total.review_0 += formItem["review_0"]["test_score"]
			      ? parseInt(formItem["review_0"]["test_score"])
			      : 0;
	      total.review_11 += formItem["review_11"]["test_score"]
			      ? parseInt(formItem["review_11"]["test_score"])
			      : 0;
      });
	    return total;
    },
	  //获取项目数据前，判断项目状态为评审中（11，31），若已到达截止时间，则更新project_status
	  update_project_status() {
		  let project_status = this.projectInfo.project_status.value
		  if ([11, 31].includes(project_status)) {
			  let limit_date = this.assign_limit_date['assign_' + (project_status === 11 ? 10 : 30)]
			  //判断是否到达截止时间
			  if (new Date().valueOf() >= moment(limit_date).add(1, 'd').valueOf) {
				  let new_project_status = project_status === 11 ? 12 : 32
				  updateProject({
					  id: this.id,
					  project_status: new_project_status,
				  }).then(() => {
					  this.getProjectInfo();
				  })
			  }
		  }
	  },
	  //判断当前页面的表单是否disabled
	  form_disabled() {
		  let step = this.step;
		  let tab = this.stepPageList[step - 1].tab;
		  let project_status = this.stepPageList[step - 1].tabs[tab].project_status;
		  let is_disabled = true
		  let current_status = this.projectInfo.project_status
		  if ((current_status !== undefined) && (current_status.value <= project_status) && current_status.value < 20) {
			  is_disabled = false
		  }
		  if ((project_status >= 20) && (this.groupType('Admins') || this.groupType('专家（评价-认定批准）') || this.groupType('专家（监督-认定批准）'))) {
			  is_disabled = false
		  }

		  return is_disabled
	  }
	  /*** 辅助函数----------------------end */
  },
  computed: {
    ...mapState({
      cos: (state) => state.cos,
    }),
  },
  async mounted() {
    this.group_page_permission();
    //console.log("user", this.user);
    //获取评审项列表
    await getTestItemArray({
      project: this.id,
	    review_no: "2020",
	    pagesize: 100000,
    }).then((res) => {
	    this.testitemList = res;
	    this.setTestItemList(res);
	    localStorage.setItem("testitemList", JSON.stringify(res));
	    //初始化表格中的数据
	    this.testitems_table_data_init();
	    this.review_decision_data_init();
    });
	  //获取已分配评审项列表
	  await this.getTestItemAssignments();
	  // 获取项目基础信息
	  await this.getProjectInfo();

	  //获取字典列表
	  getTypeList({pagesize: 10000}).then((res) => {
		  this.typeList = res.results;
		  this.setTypeList(res.results);
		  localStorage.setItem("typeList", JSON.stringify(res.results));
		  //console.log("typelist", res.results);
	  });
	  //获取建筑类型列表
	  getBuildingTypeList({pagesize: 10000}).then((res) => {
      this.buildingTypeList = res;
      //console.log("buildingTypeList", res);
    });
    // 获取评审专家列表
    getExpertList({ pagesize: 100000 }).then((res) => {
      this.expertList = this.expertList.concat(res.results);
      //console.log("expert", res.results);
    });


  },
};
</script>

<style scoped>

.n-title {
	font-size: 32px;
}


.position-sticky {
	position: sticky;
	z-index: 1;
	background: white !important;
}

.v-tab--active {
	font-weight: 800 !important;
	background-color: #1976d2;
	color: white !important;
}

.v-tabs-slider-wrapper {
	height: 3px !important;
}

tr:hover {
	background: white !important;
}

.v-input--switch.v-input--switch--inset, .v-input--radio-group.v-input--radio-group--row {
	margin-top: 5px;
}
</style>
