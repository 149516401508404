import request from "@/request";

//获取所有测评项
export const getTestItemList = (params) =>
  request.get("/api/testitems", { params });
//获取所有测评项(树形数据)
export const getTestItemArray = (params) =>
  request.get("/api/testitems/testitemarray", { params });
//获取已经分配专家的测评项
export const getTestItemAssignments = (params) =>
  request.get("/api/testitemassignments", { params });
//提交测评项的分配专家
export const addTestItemAssignments = (params) =>
  request.post("/api/testitemassignments/", params);
