import request from "@/request";


export const login = data => request.post("/api/admin/login/", data);

export const getUserList = (params) =>
  request.get("/api/system/users", { params });
export const getUserDetail = (id) => request.get(`/api/system/users/${id}/`);
export const updateUserBasicInfo = (data) =>
  request.patch(`/api/system/users/${data.id}/`, data);
export const changePassword = (data) =>
  request.post(`/api/auth/password/change/`, data);
export const getExpertList = (params) =>
  request.get(`/api/system/users`, { params });
